<template>
  <el-container
    style="min-height: 100vh; display: flex; flex-direction: column"
  >
    <el-header class="sticky-div">
      <NavBar :activeIndex="activeIndex" @menu-select="handleMenuSelect" />
    </el-header>
    <el-main class="main">
      <el-text>
        <h1>FAQ</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
          suscipit, nunc nec vehicula ultricies, nunc nunc ultricies nunc, nec
          vehicula nunc nunc nec vehicula. Nullam suscipit, nunc nec vehicula
          ultricies, nunc nunc ultricies nunc, nec vehicula nunc nunc nec
          vehicula.
        </p>
      </el-text>
    </el-main>
    <el-footer>
      <footerCom />
    </el-footer>
  </el-container>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import footerCom from "@/components/footerCom.vue";
export default {
  components: {
    NavBar,
    footerCom,
  },
};
</script>
