<template>
  <el-button @click="fetchCustomersInfo">Get Customers</el-button>
  <el-table :data="customers">
    <el-table-column prop="id" label="ID"></el-table-column>
    <el-table-column prop="username" label="Username"></el-table-column>
    <el-table-column prop="userEmail" label="Email"></el-table-column>
    <el-table-column prop="userPhone" label="Phone"></el-table-column>
    <el-table-column label="Address">
      <template v-slot="scope">
        <el-button @click="openDialog(scope.row.userAddresses)"
          >View Addresses</el-button
        >
      </template>
    </el-table-column>
  </el-table>

  <el-dialog v-model:="dialogVisible" title="User Addresses">
    <div v-for="(address, index) in currentAddresses" :key="index">
      <p><strong>First Name:</strong> {{ address.firstName }}</p>
      <p><strong>Last Name:</strong> {{ address.lastName }}</p>
      <p><strong>Email:</strong> {{ address.email }}</p>
      <p><strong>Phone:</strong> {{ address.phone }}</p>
      <p><strong>Street:</strong> {{ address.street }}</p>
      <p><strong>City:</strong> {{ address.city }}</p>
      <p><strong>State:</strong> {{ address.state }}</p>
      <p><strong>ZIP:</strong> {{ address.zip }}</p>
      <p><strong>Default:</strong> {{ address.isDefault ? "Yes" : "No" }}</p>
      <hr />
    </div>
    <div v-if="currentAddresses === null">
      <p>No addresses found.</p>
    </div>
    <span>
      <el-button @click="dialogVisible = false">Close</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getCustomersInfo } from "@/api/admin";
export default {
  data() {
    return {
      customers: [],
      dialogVisible: false,
      currentAddresses: [],
    };
  },
  methods: {
    fetchCustomersInfo() {
      const token = localStorage.getItem("token");
      getCustomersInfo(token).then((response) => {
        this.customers = response.data.customers;
        console.log(this.customers);
      });
    },
    openDialog(addresses) {
      this.currentAddresses = addresses;
      this.dialogVisible = true;
    },
  },
};
</script>
