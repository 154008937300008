<template>
  <el-container
    style="min-height: 100vh; display: flex; flex-direction: column"
  >
    <el-header class="sticky-div">
      <NavBar :activeIndex="activeIndex" @menu-select="handleMenuSelect" />
    </el-header>
    <el-container style="padding-top: 80px; height: 100%">
      <el-aside width="200px" style="margin-left: 10%">
        <el-menu>
          <el-menu-item index="/tea-info" @click="activeIndex = '3-1'"
            >Tea Info</el-menu-item
          >
          <el-menu-item index="/tea-info/tea-list" @click="activeIndex = '3-2'"
            >Tea List</el-menu-item
          >
          <el-menu-item
            index="/tea-info/tea-detail"
            @click="activeIndex = '3-3'"
            >Tea Detail</el-menu-item
          >
        </el-menu>
      </el-aside>
      <el-main style="margin-right: 10%">
        <router-view></router-view>
      </el-main>
    </el-container>
    <el-footer>
      <footerCom />
    </el-footer>
  </el-container>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import footerCom from "@/components/footerCom.vue";
export default {
  components: {
    NavBar,
    footerCom,
  },
  data() {
    return {
      activeIndex: "3-1",
    };
  },
  methods: {
    handleMenuSelect(index) {
      this.activeIndex = index;
    },
  },
};
</script>
