<template>
  <div v-if="cartItems.length === 0">
    <p>
      <el-button type="primary" text @click="naviProducts">
        {{ appLocale.cart.empty }}</el-button
      >
    </p>
  </div>

  <div v-if="cartItems.length">
    <el-table
      :data="cartItems"
      border
      style="height: 100%; width: 100%"
      table-layout="auto"
    >
      <el-table-column
        prop="product.productName"
        :label="appLocale.cart.productName"
      >
      </el-table-column>
      <el-table-column :label="appLocale.cart.quantity">
        <template v-slot="scope">
          <el-input-number
            v-model="scope.row.quantity"
            :min="1"
            @change="updateQuantity(scope.$index, scope.row.quantity)"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="selectedSpec.weight"
        :label="appLocale.cart.weight"
      >
      </el-table-column>
      <el-table-column prop="selectedSpec.price" :label="appLocale.cart.price">
      </el-table-column>
      <el-table-column
        prop="selectedSpec.bestPrice"
        :label="appLocale.cart.bestPrice"
      >
      </el-table-column>
      <el-table-column :label="appLocale.cart.total">
        <template v-slot="scope">
          {{
            scope.row.quantity *
            (scope.row.selectedSpec.bestPrice &&
            scope.row.selectedSpec.bestPrice !== ""
              ? Number(scope.row.selectedSpec.bestPrice)
              : Number(scope.row.selectedSpec.price))
          }}
        </template>
      </el-table-column>
      <el-table-column :label="appLocale.cart.actions" width="100">
        <template v-slot="scope">
          <el-button type="danger" circle @click="removeFromCart(scope.$index)">
            <el-icon><Delete /></el-icon>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div>
      <h3>
        {{ appLocale.cart.grandTotal }}:
        {{
          cartItems.reduce((acc, item) => {
            const price = Number(item.selectedSpec.price);
            return acc + item.quantity * price;
          }, 0)
        }}
      </h3>

      <h3>
        {{ appLocale.cart.subTotal }}
        {{
          cartItems.reduce((acc, item) => {
            const price =
              item.selectedSpec.bestPrice && item.selectedSpec.bestPrice !== ""
                ? Number(item.selectedSpec.bestPrice)
                : Number(item.selectedSpec.price);
            return acc + item.quantity * price;
          }, 0)
        }}
      </h3>
    </div>
  </div>
</template>

<script>
import { localeState } from "@/locale.js";
export default {
  computed: {
    cartItems() {
      return this.$store.state.cartItems; // Access the store globally
    },
    appLocale() {
      return localeState.appLocale;
    },
  },
  methods: {
    removeFromCart(index) {
      this.$store.mutations.removeFromCart(index);
    },
    naviProducts() {
      this.$router.push("/tea");
    },
    updateQuantity(index, quantity) {
      this.$store.mutations.updateQuantity(index, quantity);
    },
  },
};
</script>
