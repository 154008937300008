<template>
  <el-container>
    <el-header class="sticky-div">
      <NavBar :activeIndex="activeIndex" @menu-select="handleMenuSelect" />
    </el-header>
    <el-main class="main">
      <div v-if="product" class="product-main">
        <el-row justify="space-around" style="min-height: 400px; display: flex">
          <el-col :span="10" style="display: flex; flex-direction: column">
            <el-carousel height="100%" style="flex: 1">
              <el-carousel-item
                v-for="image in this.productImages"
                :key="image.id"
              >
                <img :src="HOST + image.url" class="cover-img" />
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :span="10" style="display: flex; flex-direction: column">
            <el-card style="flex: 1">
              <el-row>
                <el-col :span="24">
                  <h1>{{ product.productName }}</h1>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <p>{{ appLocale.productDetail.priceFrom }}</p>
                  <p>{{ appLocale.productDetail.quantity }}</p>
                </el-col>
                <el-col :span="16">
                  <p>
                    <strong
                      v-if="
                        selectedSpecification.bestPrice &&
                        selectedSpecification.bestPrice > 0
                      "
                    >
                      <span
                        style="text-decoration: line-through; margin-right: 5px"
                      >
                        {{ selectedSpecification.price }}
                      </span>
                      <span
                        >{{
                          selectedSpecification.bestPrice +
                          appLocale.productDetail.per
                        }}
                        {{
                          selectedSpecification.weight +
                          appLocale.productDetail.g
                        }}</span
                      >
                    </strong>
                    <strong v-else>
                      {{
                        selectedSpecification.price +
                        appLocale.productDetail.per
                      }}
                      {{
                        selectedSpecification.weight + appLocale.productDetail.g
                      }}
                    </strong>
                  </p>
                  <el-input-number
                    v-model="num"
                    :min="1"
                    :max="10"
                    @change="handleChange"
                    label="Quantity"
                  />
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="24">
                  <p>
                    <strong>{{
                      appLocale.productDetail.specifications
                    }}</strong>
                  </p>
                  <el-radio-group
                    v-model="selectedSpecification"
                    @change="handleSpecChange"
                  >
                    <el-radio-button
                      v-for="spec in product.productSpecifications"
                      :key="spec.id"
                      :label="
                        spec.price +
                        ' ' +
                        appLocale.productDetail.per +
                        ' ' +
                        spec.weight +
                        appLocale.productDetail.g
                      "
                      :value="spec"
                    >
                    </el-radio-button>
                  </el-radio-group>
                  <el-divider>{{
                    appLocale.productDetail.selectedSpecification
                  }}</el-divider>
                  <strong>{{
                    selectedSpecification.weight + appLocale.productDetail.g
                  }}</strong>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-divider />
                  <p>
                    <strong>{{ appLocale.productDetail.description }}</strong>
                  </p>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-divider>{{
                    appLocale.productDetail.totalPrice
                  }}</el-divider>
                  <strong>{{ selectedSpecification.price * num }}</strong>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-divider />
                  <el-button type="primary" @click="addToCart(product)">{{
                    appLocale.productDetail.addToCart
                  }}</el-button>
                  <el-button type="primary" @click="buyNow(product)">{{
                    appLocale.productDetail.buyNow
                  }}</el-button>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>

        <el-divider></el-divider>
        <el-row>
          <el-col :span="24">
            <el-tabs type="border-card" stretch>
              <el-tab-pane :label="appLocale.productDetail.productDetails">
                <el-descriptions :column="3" border size="small">
                  <el-descriptions-item
                    v-for="(item, index) in product"
                    :key="index"
                    :label="formatLabel(index)"
                  >
                    {{ item }}
                  </el-descriptions-item>
                </el-descriptions>
              </el-tab-pane>
              <el-tab-pane :label="appLocale.productDetail.productPhotos">
                <div v-for="image in this.productImages" :key="image.id">
                  <img :src="HOST + image.url" class="cover-img" />
                </div>
              </el-tab-pane>
              <el-tab-pane :label="appLocale.productDetail.shippingInfo">
                <p>Shipping Info</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                  nonne merninisti licere mihi ista probare, quae sunt a te
                  dicta? Refert tamen, quo modo.
                </p>
              </el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>
      </div>
      <div v-else>
        <p>{{ appLocale.common.loading }}</p>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { getProductsDataById } from "/src/api/product.js";
import NavBar from "/src/components/NavBar.vue";
import { HOST } from "/src/utils/var.js";
import { localeState } from "@/locale.js";
export default {
  components: {
    NavBar,
  },
  computed: {
    appLocale() {
      return localeState.appLocale;
    },
  },
  data() {
    return {
      product: null,
      productId: null,
      productImages: [],
      num: 1,
      HOST,
      selectedSpecification: {
        id: null,
        title: null,
        weight: null,
        price: null,
      },
    };
  },
  created() {
    this.productId = this.$route.params.id;
    getProductsDataById(this.productId).then((response) => {
      this.product = response.data;
      this.productImages = response.data.productImages;
      delete this.product.productImages;
      console.log(this.product);
      this.sortDefaultSpec();
    });
  },
  mounted() {},
  methods: {
    handleMenuSelect(index) {
      this.activeIndex = index;
    },
    handleChange(value) {
      this.num = value;
    },
    addToCart(product) {
      product.id = this.productId;
      this.$store.mutations.addToCart(
        product,
        this.num,
        this.selectedSpecification
      );
    },
    buyNow(product) {
      this.addToCart(product);
      this.$router.push("/checkout");
    },
    handleSpecChange(value) {
      this.selectedSpecification = value;
    },
    formatLabel(camelCaseString) {
      return camelCaseString
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space between lowercase and uppercase letters
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Insert space between consecutive uppercase letters and following lowercase
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter of the resulting string
    },
    sortDefaultSpec() {
      if (this.product.productSpecifications.length > 0) {
        const sortedSpecs = [...this.product.productSpecifications].sort(
          (a, b) => a.weight - b.weight
        );
        this.selectedSpecification = sortedSpecs[0];
      }
    },
  },
};
</script>

<style scoped>
.main {
  padding-top: 80px;
  height: 100%;
}
.cover-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel {
  margin-bottom: 20px;
}
.product-main {
  width: 70%;
  margin: 0 auto;
  justify-content: center;
}
</style>
