<template>
  <el-tabs v-model="activeTab" @tab-click="handleTabClick">
    <el-tab-pane label="Home Configuration" name="homeConfig">
      <h1>Home Configuration</h1>
      <el-form :model="homeConfig" label-width="120px">
        <el-form-item label="Home Page Text">
          <el-input v-model="homeConfig.homePageText" />
        </el-form-item>
        <el-form-item label="Home Page Image">
          <el-upload
            ref="homePageImageUpload"
            :limit="1"
            :on-exceed="handleExceedHomePageImage"
            :auto-upload="false"
            list-type="picture"
            :file-list="homePageImage"
            :on-change="handleChangeHomePageImage"
          >
            <template #trigger>
              <el-button type="primary">Select file</el-button>
            </template>
            <template #tip>
              <div>Only upload one image, under 5m</div>
            </template>
            <el-button
              style="margin-left: 10px"
              @click="handleUploadHomePageImage"
              >Upload</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="Carousel Images">
          <el-upload
            ref="carouselImageUpload"
            :limit="5"
            :on-exceed="handleExceedCarouselImages"
            :auto-upload="false"
            list-type="picture"
            :file-list="carouselImages"
            :on-change="handleChangeCarouselImages"
            :on-remove="handleRemoveCarouselImage"
          >
            <template #trigger>
              <el-button type="primary">Select files</el-button>
            </template>
            <template #tip>
              <div>Only upload five images, under 5m</div>
            </template>
            <el-button
              style="margin-left: 10px"
              @click="handleUploadCarouselImages"
              >Upload</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item label="Categories">
          <div v-for="item in homeConfig.category" :key="item.id">
            <h2>Category {{ item.id }}</h2>
            <el-input v-model="item.title" label="Title" />
            <el-input
              label="Description"
              :autosize="{ minRows: 2, maxRows: 4 }"
              type="textarea"
              v-model="item.description"
            />
          </div>
        </el-form-item>
        <el-form-item label="Story" v-if="this.homeConfig.story">
          <el-input label="Title" v-model="this.homeConfig.story.title" />
          <el-input
            label="Description"
            :autosize="{ minRows: 2, maxRows: 4 }"
            type="textarea"
            v-model="this.homeConfig.story.content"
          />
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="saveHomeConfig">Save</el-button>
    </el-tab-pane>
    <el-tab-pane label="Website Configuration" name="websiteConfig">
      <div>
        <h1>暂时用不上</h1>
      </div>
      <el-form :model="websiteConfig" label-width="120px">
        <el-form-item
          v-for="(value, key) in websiteConfig"
          :key="key"
          :label="key"
        >
          <el-input v-model="websiteConfig[key]" />
        </el-form-item>
        <!-- <el-form-item label="favicon">
          <el-upload
            :limit="1"
            :on-exceed="handleExceedFavicon"
            :auto-upload="false"
            list-type="picture"
            :file-list="favicon"
          >
            <template #trigger>
              <el-button type="primary">Select file</el-button>
            </template>
            <el-button style="margin-left: 10px" @click="handleUploadFavicon"
              >Upload</el-button
            >
          </el-upload>
        </el-form-item> -->
      </el-form>
      <el-button type="primary" @click="saveWebsiteConfig">Save</el-button>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import {
  getWebsiteConfig,
  updateWebsiteConfig,
  updateHomeData,
  uploadHomePageImage,
  uploadCarouselImage,
  deleteCarouselImage,
} from "@/api/admin";
import { getHomeData } from "@/api/home";
import { HOST } from "@/utils/var.js";
export default {
  data() {
    return {
      websiteConfig: {},
      homeConfig: [],
      homePageImage: [],
      carouselImages: [],
      HOST,
      activeTab: "homeConfig",
      favicon: [],
    };
  },
  created() {
    this.fetchConfigs();
  },
  methods: {
    handleTabClick(tab) {
      console.log(tab);
    },
    fetchConfigs() {
      const token = localStorage.getItem("token");
      if (token) {
        getWebsiteConfig(token).then((response) => {
          this.websiteConfig = response.data.websiteConfig;
          console.log(this.websiteConfig);
        });
      }

      getHomeData(token).then((response) => {
        this.homeConfig = response.data.homeData;
        console.log(this.homeConfig);
        this.homePageImage = [
          {
            name: "Home Page Image",
            url: this.HOST + this.homeConfig.homePageImage,
          },
        ];
        this.carouselImages = this.homeConfig.carouselImages.map((image) => {
          return {
            name: image.name,
            url: this.HOST + image.url,
          };
        });
        console.log(this.carouselImages);
      });
    },
    saveWebsiteConfig() {
      console.log("Saving website config");
      const token = localStorage.getItem("token");
      const data = this.websiteConfig;
      updateWebsiteConfig(data, token).then((response) => {
        console.log("Website config updated" + response);
      });
    },
    saveHomeConfig() {
      const token = localStorage.getItem("token");
      const data = {
        homePageText: this.homeConfig.homePageText,
        story: this.homeConfig.story,
        category: this.homeConfig.category,
      };
      updateHomeData(data, token).then((response) => {
        console.log("Home data updated" + response);
      });
    },
    handleExceedHomePageImage(files) {
      this.$refs.homePageImageUpload.clearFiles();
      const file = files[0];
      file.uid = Date.now();
      this.$refs.homePageImageUpload.handleStart(file);
    },
    handleExceedCarouselImages(files, fileList) {
      console.log("Exceed", files, fileList);
    },
    handleChangeHomePageImage(file, fileList) {
      console.log("File", file);
      this.homePageImage = fileList;
      console.log("Home page image", this.homePageImage);
    },
    handleChangeCarouselImages(file, fileList) {
      console.log("File", file);
      this.carouselImages = fileList;
      console.log("Carousel images", this.carouselImages);
    },

    handleUploadHomePageImage() {
      const token = localStorage.getItem("token");
      const uploadPromise = this.homePageImage.map((file) => {
        const formData = new FormData();
        formData.append("file", file.raw);
        return uploadHomePageImage(formData, token);
      });
      Promise.all(uploadPromise).then((response) => {
        console.log("Uploaded", response);
      });
    },
    handleUploadCarouselImages() {
      const token = localStorage.getItem("token");

      const uploadPromise = this.carouselImages
        .filter((file) => file.status === "ready")
        .map((file) => {
          const formData = new FormData();
          formData.append("file", file.raw);
          console.log("File", file);
          return uploadCarouselImage(formData, token);
        });
      Promise.all(uploadPromise)
        .then((responses) => {
          responses.forEach((response) => {
            console.log("Uploaded File Response:", response.data);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleRemoveCarouselImage(file, fileList) {
      console.log("Remove", file, fileList);
      const token = localStorage.getItem("token");
      const data = {
        filename: file.name,
      };
      deleteCarouselImage(data, token)
        .then((response) => {
          console.log("Deleted", response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
