<template>
  <el-container class="container">
    <el-header class="header">
      <NavBar />
    </el-header>
    <el-main class="main"> </el-main>
    <el-footer class="footer">
      <footerCom />
    </el-footer>
  </el-container>
</template>

<script>
import NavBar from "/src/components/NavBar.vue";
import footerCom from "@/components/footerCom.vue";

export default {
  components: {
    NavBar,
    footerCom,
  },
  methods: {},
};
</script>

<style scoped>
.container {
  display: flex;
}
.header {
  position: fixed;
  width: 100%;
  top: 0px;
}

.footer {
}

.main {
  background-color: #007efc;
  color: #333;
  text-align: center;
  line-height: 160px;
  min-height: 100vh;
}
</style>
