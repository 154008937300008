import request from "../utils/request";

export function guestPlaceOrder(jsondata) {
  return request.axiosRequest("/pujing/guestPlaceOrder", jsondata);
}
export function userPlaceOrder(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthPost("/pujing/userPlaceOrder", jsondata, config);
}

export function placeOrder(jsondata) {
  return request.axiosAuthPost("/pujing/placeOrder", jsondata);
}
export function placeOrderWithToken(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthPost("/pujing/placeOrder", jsondata, config);
}
