<template>
  <div class="footer">
    <el-row :gutter="20" justify="center">
      <el-col :span="6">
        <h2>{{ appLocale.footer.quickLinks }}</h2>
        <el-tooltip content="Home" placement="top">
          <el-button type="text" @click="navigateTo('/')">{{
            appLocale.navbar.home
          }}</el-button>
        </el-tooltip>
        <el-tooltip content="Products" placement="top">
          <el-button type="text" @click="navigateTo('/tea')">{{
            appLocale.navbar.tea
          }}</el-button>
        </el-tooltip>
      </el-col>
      <el-col :span="6">
        <h2>{{ appLocale.footer.company }}</h2>
        <el-tooltip content="Tea Info" placement="top">
          <el-button type="text" @click="navigateTo('/tea-info')">{{
            appLocale.navbar.teaInfo
          }}</el-button>
        </el-tooltip>
        <el-tooltip content="FAQs" placement="top">
          <el-button type="text" @click="navigateTo('/faq')">FAQs</el-button>
        </el-tooltip>
      </el-col>
      <el-col :span="6">
        <h2>{{ appLocale.footer.contact }}</h2>
        <div class="social-icons">
          <a href="https://www.facebook.com" target="_blank">
            <el-button type="text" icon>
              <img :src="icon_fb" alt="Facebook" />
            </el-button>
          </a>
          <a href="https://www.instagram.com" target="_blank">
            <el-button type="text" icon>
              <img :src="icon_instagram" alt="Instagram" />
            </el-button>
          </a>
          <a href="https://www.linkedin.com" target="_blank">
            <el-button type="text" icon>
              <img :src="icon_linkedin" alt="LinkedIn" />
            </el-button>
          </a>
          <a href="https://www.messenger.com" target="_blank">
            <el-button type="text" icon>
              <img :src="icon_messenger" alt="Messenger" />
            </el-button>
          </a>
          <a href="mailto:example@example.com">
            <el-button type="text" icon>
              <img :src="icon_email" alt="Email" />
            </el-button>
          </a>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import icon_fb from "@/assets/icons/icon_fb.svg";
import icon_instagram from "@/assets/icons/icon_instagram.svg";
import icon_linkedin from "@/assets/icons/icon_linkedin.svg";
import icon_messenger from "@/assets/icons/icon_messenger.svg";
import icon_email from "@/assets/icons/icon_email.svg";
import { localeState } from "@/locale.js";
export default {
  data() {
    return {
      icon_fb,
      icon_instagram,
      icon_linkedin,
      icon_messenger,
      icon_email,
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
  },
  computed: {
    appLocale() {
      return localeState.appLocale;
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
}
.social-icons a {
  margin: 0 10px;
}
.social-icons img {
  width: 24px;
  height: 24px;
}
</style>
