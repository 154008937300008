<template>
  <el-menu
    :default-active="activeIndex"
    mode="horizontal"
    :ellipsis="false"
    @select="handleMenuSelect"
    menu-trigger="click"
    background-color="#158a51"
    text-color="#fff"
    close-on-click-outside="true"
  >
    <!-- Left Zone:Drawer Trigger on Small Screens -->
    <el-menu-item
      class="left-zone"
      index="0"
      @click="toggleMenuDrawer"
      style="width: 200px"
    >
      <el-icon><Menu /></el-icon>
    </el-menu-item>

    <!-- Middle Zone: Menu Items -->
    <div class="middle-zone">
      <el-image :src="logo" style="width: 210px; height: 60px" />
      <div class="menu-items">
        <el-menu-item index="1">
          <template #title>
            <el-icon><HomeFilled /></el-icon>
            <span>{{ appLocale.navbar.home }}</span>
          </template>
        </el-menu-item>
        <el-menu-item index="2">{{ appLocale.navbar.tea }}</el-menu-item>
        <el-menu-item index="3">{{ appLocale.navbar.teaware }}</el-menu-item>
        <el-menu-item index="4">{{ appLocale.navbar.teaGift }}</el-menu-item>
        <el-sub-menu index="3-0">
          <template #title>{{ appLocale.navbar.teaInfo }}</template>
          <el-menu-item index="3-1">{{
            appLocale.navbar.teaInfo
          }}</el-menu-item>
          <el-menu-item index="3-2">{{
            appLocale.navbar.teaList
          }}</el-menu-item>
          <el-menu-item index="3-3">{{
            appLocale.navbar.teaDetail
          }}</el-menu-item>
        </el-sub-menu>
        <el-badge :value="cartItems.length" :offset="[-12, 10]">
          <el-menu-item index="5">
            <div v-if="cartItems.length > 0">
              <el-icon><ShoppingCartFull /></el-icon>
            </div>
            <div v-else>
              <el-icon><ShoppingCart /></el-icon>
            </div>
            {{ appLocale.navbar.cart }}
          </el-menu-item>
        </el-badge>
        <!-- Login Menu Item -->
        <div v-if="!isLoggedIn" class="auth-zone">
          <el-menu-item index="6"
            ><el-icon><User /></el-icon
            >{{ appLocale.navbar.login }}</el-menu-item
          >
        </div>

        <!-- Logged In User Sub-Menu -->
        <el-sub-menu v-if="isLoggedIn" index="7">
          <template #title
            ><el-icon><UserFilled /></el-icon
            >{{ appLocale.navbar.loggedIn + getUserName() }}</template
          >
          <el-menu-item @click="openAccount" index="7-1">{{
            appLocale.navbar.account
          }}</el-menu-item>
          <el-menu-item @click="logout" index="7-2">{{
            appLocale.navbar.logout
          }}</el-menu-item>
        </el-sub-menu>
        <el-menu-item>
          <el-switch
            v-model="activeLocale"
            inline-prompt
            active-text="Eng"
            inactive-text="中"
            active-value="en"
            inactive-value="zh-cn"
            @change="handleSwitchChange"
          />
        </el-menu-item>
      </div>
    </div>

    <div class="right-zone">
      <el-menu-item @click="toggleCartDrawer">
        <el-icon><ShoppingCart /></el-icon>
      </el-menu-item>
    </div>
  </el-menu>
  <!-- Drawer for Menu Items on Small Screens -->
  <el-drawer
    title="Menu"
    v-model="menuDrawerVisible"
    direction="ltr"
    class="menu-drawer"
    size="100%"
  >
    <el-menu
      :default-active="activeIndex"
      @select="handleMenuSelect"
      class="drawer-menu"
      background-color="#158a51"
      text-color="#fff"
    >
      <el-menu-item index="1">
        <template #title>
          <el-icon><HomeFilled /></el-icon>
          <span>{{ appLocale.navbar.home }}</span>
        </template>
      </el-menu-item>
      <el-menu-item index="2">{{ appLocale.navbar.tea }}</el-menu-item>
      <el-menu-item index="3">{{ appLocale.navbar.teaware }}</el-menu-item>
      <el-menu-item index="4">{{ appLocale.navbar.teaGift }}</el-menu-item>
      <el-sub-menu index="3-0">
        <template #title>{{ appLocale.navbar.teaInfo }}</template>
        <el-menu-item index="3-1">{{ appLocale.navbar.teaInfo }}</el-menu-item>
        <el-menu-item index="3-2">{{ appLocale.navbar.teaList }}</el-menu-item>
        <el-menu-item index="3-3">{{
          appLocale.navbar.teaDetail
        }}</el-menu-item>
      </el-sub-menu>
      <el-menu-item index="5">
        <el-icon><ShoppingCart /></el-icon>{{ appLocale.navbar.cart }}
      </el-menu-item>

      <!-- Login Menu Item -->
      <div v-if="!isLoggedIn" class="auth-zone">
        <el-menu-item index="6"
          ><el-icon><User /></el-icon>{{ appLocale.navbar.login }}</el-menu-item
        >
      </div>

      <!-- Logged In User Sub-Menu -->
      <el-sub-menu v-if="isLoggedIn" index="7">
        <template #title
          ><el-icon><UserFilled /></el-icon
          >{{ appLocale.navbar.loggedIn + getUserName() }}</template
        >
        <el-menu-item @click="openAccount" index="7-1">{{
          appLocale.navbar.account
        }}</el-menu-item>
        <el-menu-item @click="logout" index="7-2">{{
          appLocale.navbar.logout
        }}</el-menu-item>
      </el-sub-menu>
      <el-menu-item>
        <el-switch
          v-model="activeLocale"
          inline-prompt
          active-text="Eng"
          inactive-text="中"
          active-value="en"
          inactive-value="zh-cn"
          @change="handleSwitchChange"
      /></el-menu-item>
    </el-menu>
  </el-drawer>
  <el-drawer
    title="Cart"
    v-model="cartDrawerVisible"
    direction="rtl"
    class="cart-drawer"
    :size="cartDrawerSize"
  >
    <CartCom />
    <el-button type="primary" @click="checkOut">
      {{ appLocale.cart.checkout }}</el-button
    >
  </el-drawer>
</template>

<script>
import CartCom from "./CartCom.vue";
import logo from "@/assets/logo.jpg";
import { localeState } from "@/locale.js";
export default {
  components: {
    CartCom,
  },
  data() {
    return {
      menuDrawerVisible: false,
      cartDrawerVisible: false,
      isOpenCart: true,
      logo,
      selected: "",
      isLoggedIn: !!localStorage.getItem("token"),
      activeLocale: "en",
      cartDrawerSize: "100%",
    };
  },
  computed: {
    cartItems() {
      return this.$store.state.cartItems; // Access the store globally
    },
    appLocale() {
      return localeState.appLocale;
    },
  },
  mounted() {
    this.updateCartDrawerSize();
    window.addEventListener("resize", this.updateCartDrawerSize);
  },
  props: {
    activeIndex: String,
  },
  methods: {
    updateCartDrawerSize() {
      const width = window.innerWidth; // Get the current window width
      if (width < 500) {
        this.cartDrawerSize = "100%"; // Set size to 80% for screens < 800px
      } else if (width < 1200) {
        this.cartDrawerSize = "50%"; // Set size to 50% for screens < 1200px
      } else {
        this.cartDrawerSize = "30%"; // Set size to 100% for larger screens
      }
    },
    handleMenuSelect(index) {
      // this.$emit("menu-select", index);
      switch (index) {
        case "1":
          this.$router.push("/");
          break;
        case "2":
          this.$router.push("/tea");
          break;
        case "3":
          this.$router.push("/teaware");
          break;
        case "3-1":
          this.$router.push("/tea-info");
          break;
        case "3-2":
          this.$router.push("/tea-info/tea-list");
          break;
        case "3-3":
          this.$router.push("/tea-info/tea-detail");
          break;
        case "4":
          this.$router.push("/tea-gift");
          break;
        case "5":
          this.toggleCartDrawer();
          break;
        case "6":
          this.$router.push("/login");
          break;
        default:
          break;
      }
    },
    openAccount() {
      this.$router.push("/account");
    },
    getUserName() {
      return localStorage.getItem("userName");
    },
    logout() {
      this.$store.mutations.logout();
      this.isLoggedIn = !!localStorage.getItem("token");
    },
    removeFromCart(index) {
      this.$store.commit("removeFromCart", index);
    },
    toggleMenuDrawer() {
      this.menuDrawerVisible = !this.menuDrawerVisible; // Toggles the drawer visibility
    },
    toggleCartDrawer() {
      this.cartDrawerVisible = !this.cartDrawerVisible; // Toggles the drawer visibility
    },
    handleSwitchChange(value) {
      localeState.setLanguage(value);
    },
    checkOut() {
      this.$router.push("/checkout");
    },
  },
};
</script>

<style scoped>
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cart {
  display: none;
}

.left-zone {
  flex: 1;
  display: none;
  align-items: center;
}

.middle-zone {
  flex: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-zone {
  flex: 1;
  display: none;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 1115px) {
  .menu-items {
    display: none; /* Hide menu items on smaller screens */
  }

  .left-zone {
    display: flex;
    flex: 2;
  }

  .right-zone {
    display: flex;
    flex: 2;
  }
  .cart-drawer {
    width: 80%;
    size: 100%;
  }
}
</style>
