import request from "../utils/request";

export function getProductData() {
  return request.axiosGet("/pujing/test");
}
export function getProductsData() {
  return request.axiosGet("/pujing/getTeaProducts");
}
export function getProductsDataById(id) {
  return request.axiosGet(`/pujing/getTeaProductsById/${id}`);
}
export function getProductsByCategory(category) {
  return request.axiosGet(`/pujing/getProductsByCategory//${category}`);
}
