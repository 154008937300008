<template>
  <el-container>
    <el-main>
      <el-tabs v-model="activeTab" @tab-click="handleTabClick">
        <el-tab-pane label="Product Management" name="productManagement">
          <h1>Product Management</h1>
          <el-button
            type="primary"
            round
            @click="fetchProductsData"
            style="margin-bottom: 20px"
            >Fetch Product Data</el-button
          >
          <el-button
            type="success"
            round
            @click="onClickAdd"
            style="margin-bottom: 20px"
            >Add Product</el-button
          >
          <el-table
            :data="products"
            style="width: 100%"
            stripe
            border
            fit
            highlight-current-row
            v-loading="loading"
          >
            <el-table-column prop="id" label="ID" sortable />
            <el-table-column prop="productName" label="Name" sortable />
            <el-table-column prop="productPrice" label="Price" sortable />
            <el-table-column
              prop="productBestPrice"
              label="Best Price"
              sortable
            />
            <el-table-column prop="productType" label="Type" />
            <el-table-column prop="productDescription" label="Description" />
            <el-table-column prop="productCost" label="Cost" />
            <el-table-column prop="productPackType" label="PackType" />
            <el-table-column
              prop="productYearOfProduction"
              label="YearOfProduction"
            />
            <el-table-column prop="productLocation" label="Location" />
            <el-table-column prop="productWeight" label="Weight" />
            <el-table-column prop="productCategory" label="Category" />
            <el-table-column fixed="right" label="Actions" width="300">
              <template #default="{ row }">
                <el-button type="primary" size="small" @click="handleEdit(row)"
                  >Edit</el-button
                >
                <el-popconfirm
                  title="Are you sure to delete this?"
                  @confirm="handleDelete(row)"
                >
                  <template #reference>
                    <el-button type="danger" size="small">Delete</el-button>
                  </template>
                </el-popconfirm>
                <el-button
                  type="info"
                  size="small"
                  @click="handleUpload(row.id)"
                  >Upload Image</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-dialog
            title="Edit Product"
            v-model="dialogFormVisible"
            @close="dialogFormVisible = false"
          >
            <el-form :model="dialogForm" label-width="100px">
              <el-form-item label="ID">
                <el-input v-model="dialogForm.id" disabled />
              </el-form-item>
              <el-form-item label="Name">
                <el-input v-model="dialogForm.productName" />
              </el-form-item>
              <el-form-item label="Price">
                <el-input
                  v-model="dialogForm.productPrice"
                  :formatter="
                    (value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                  :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
                />
              </el-form-item>
              <el-form-item label="Best Price">
                <el-input
                  v-model="dialogForm.productBestPrice"
                  :formatter="
                    (value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                  :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
                />
              </el-form-item>
              <el-form-item label="Description">
                <el-input v-model="dialogForm.productDescription" />
              </el-form-item>
              <el-form-item label="Type">
                <el-input v-model="dialogForm.productType" />
              </el-form-item>

              <el-form-item label="Cost">
                <el-input
                  v-model="dialogForm.productCost"
                  :formatter="
                    (value) =>
                      `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  "
                  :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
                />
              </el-form-item>
              <el-form-item label="PackType">
                <el-input v-model="dialogForm.productPackType" />
              </el-form-item>
              <el-form-item label="YearOfProduction">
                <el-date-picker
                  v-model="dialogForm.productYearOfProduction"
                  type="year"
                  placeholder="Pick a year"
                  format="YYYY"
                  value-format="YYYY"
                />
              </el-form-item>
              <el-form-item label="Location">
                <el-input v-model="dialogForm.productLocation" />
              </el-form-item>
              <el-form-item label="Weight">
                <el-input v-model="dialogForm.productWeight"
                  ><template #append>g</template></el-input
                >
              </el-form-item>
              <el-form-item label="Category">
                <el-select v-model="dialogForm.productCategory">
                  <el-option
                    v-for="category in categories"
                    :key="category.id"
                    :label="category.label"
                    :value="category.value"
                  />
                </el-select>
              </el-form-item>
              <el-divider>Product Specifications</el-divider>
              <div
                v-for="(spec, index) in dialogForm.productSpecifications"
                :key="index"
              >
                <el-form-item :label="'Specification' + (index + 1)">
                  <el-input v-model="spec.id" placeholder="Spec ID" disabled
                    ><template #prepend>Spec id</template>
                  </el-input>
                  <el-input v-model="spec.title" placeholder="Enter Spec Title">
                    <template #prepend>Spec title</template>
                  </el-input>
                  <el-input
                    v-model="spec.weight"
                    placeholder="Enter Spec Weight (g)"
                    type="number"
                    ><template #prepend>Spec Weight</template></el-input
                  >
                  <el-input
                    v-model="spec.price"
                    placeholder="Enter Spec Price ($)"
                    type="number"
                    prefix="$"
                    ><template #prepend>Spec Price</template></el-input
                  >
                  <el-input
                    v-model="spec.bestPrice"
                    placeholder="Enter Sale Price ($)"
                    type="number"
                    prefix="$"
                    ><template #prepend>Sale Price</template></el-input
                  >
                  <el-button type="danger" @click="removeSpecification(index)"
                    >Remove</el-button
                  >
                </el-form-item>
              </div>

              <el-form-item>
                <el-button type="primary" @click="addSpecification"
                  >Add Specification</el-button
                >
              </el-form-item>
              <div class="dialog-footer">
                <el-button @click="dialogFormVisible = false">Cancel</el-button>
                <el-button type="primary" @click="saveEditedProduct"
                  >Save</el-button
                >
              </div>
            </el-form>
          </el-dialog>
          <el-dialog
            title="Upload Image"
            v-model="dialogImageVisible"
            @close="dialogImageVisible = false"
          >
            <div v-loading="imageDialogLoading">
              <h2>Upload Cover Image</h2>
              <el-upload
                ref="uploadCover"
                v-model:file-list="coverImage"
                list-type="picture-card"
                :auto-upload="false"
                :on-preview="handlePictureCardPreview"
                :limit="1"
                :on-exceed="handleCoverExceed"
              >
                <el-icon><Plus /></el-icon>
                <template #tip>
                  <div>Only upload file under 5m</div>
                </template>
              </el-upload>
              <el-button
                type="primary"
                @click="uploadProductCover"
                :disabled="coverImage.length === 0"
                >Upload Cover</el-button
              >
              <el-divider />
              <h2>Upload Images</h2>
              <el-upload
                v-model:file-list="dialogImageForm.fileList"
                list-type="picture-card"
                :auto-upload="false"
                :on-preview="handlePictureCardPreview"
                :on-change="handleChange"
                :on-remove="handleRemove"
              >
                <el-icon><Plus /></el-icon>
                <template #tip>
                  <div>Only upload file under 5m</div>
                </template>
              </el-upload>

              <el-button
                type="primary"
                @click="uploadProductImage"
                :disabled="dialogImageForm.fileList.length === 0"
                >Upload All</el-button
              >
            </div>
          </el-dialog>
          <el-dialog v-model="dialogVisible">
            <img :src="dialogImageUrl" alt="Preview Image" w-full />
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane
          label="Featured Products Management"
          name="featuredProductsManagement"
        >
          <el-transfer
            v-model="featuredProductsIDList"
            :data="products"
            :props="{
              key: 'id',
              label: 'productName',
            }"
            filterable
            filter-placeholder="Search for products"
            :titles="['Not Featured', 'Featured']"
          />
          <el-button type="primary" @click="resetFeaturedProducts"
            >Reset</el-button
          >
          <el-button type="primary" @click="saveFeaturedProducts"
            >Save</el-button
          >
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import { getAllProducts, updateFeaturedProducts } from "@/api/admin";
import { HOST } from "@/utils/var.js";
import { getFeaturedProducts } from "@/api/home";
import { ElMessage, genFileId } from "element-plus";
import {
  addProduct,
  updateProduct,
  deleteProduct,
  uploadImage,
  getProductImages,
  deleteProductImage,
  uploadCover,
} from "@/api/admin";

export default {
  data() {
    return {
      activeTab: "productManagement",
      loading: false,
      imageDialogLoading: false,
      HOST,
      products: [],
      featuredProductsIDList: [],
      dialogFormVisible: false,
      dialogForm: {
        id: "",
        productName: "",
        productPrice: "",
        productDescription: "",
        productType: "",
        productWeight: "",
        productBestPrice: "",
        productYearOfProduction: "",
        productCost: "",
        productLocation: "",
        productPackType: "",
        productCategory: "",
        productSpecifications: [
          {
            id: "",
            title: "test1",
            weight: "",
            price: "",
            bestPrice: "",
          },
          {
            id: "",
            title: "test2",
            weight: "",
            price: "",
            bestPrice: "",
          },
        ],
      },
      dialogImageVisible: false,
      dialogImageForm: {
        id: "",
        fileList: [],
      },
      dialogVisible: false,
      dialogImageUrl: "",
      coverImage: [],
      categories: [
        {
          id: 1,
          value: "Tea",
          label: "Tea",
        },
        {
          id: 2,
          value: "Teaware",
          label: "Teaware",
        },
        {
          id: 3,
          value: "TeaGift",
          label: "TeaGift",
        },
      ],
    };
  },
  created() {
    this.fetchProductsData();
  },
  methods: {
    handleEdit(row) {
      console.log("Edit", row);
      this.dialogForm = row;
      this.dialogFormVisible = true;
    },
    handleDelete(row) {
      console.log("Delete", row);
      const token = localStorage.getItem("token");
      const data = {
        id: row.id,
      };
      deleteProduct(data, token)
        .then((response) => {
          console.log(response.data);
          this.fetchProductsData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchProductsData() {
      this.adminFetchProductsData();
    },

    adminFetchProductsData() {
      this.loading = true;
      const token = localStorage.getItem("token");
      getAllProducts(token)
        .then((response) => {
          this.products = response.data.products;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    onClickAdd() {
      this.dialogForm = {
        productName: "",
        productPrice: "",
        productDescription: "",
        productType: "",
        productSpecifications: [],
      };
      this.dialogFormVisible = true;
    },
    saveEditedProduct() {
      this.loading = true;
      if (this.dialogForm.id) {
        // Update product
        console.log("Update product", this.dialogForm);
        const token = localStorage.getItem("token");
        const data = {
          id: this.dialogForm.id,
          productName: this.dialogForm.productName,
          productPrice: this.dialogForm.productPrice,
          productDescription: this.dialogForm.productDescription,
          productType: this.dialogForm.productType,
          productWeight: this.dialogForm.productWeight,
          productBestPrice: this.dialogForm.productBestPrice,
          productYearOfProduction: this.dialogForm.productYearOfProduction,
          productCost: this.dialogForm.productCost,
          productLocation: this.dialogForm.productLocation,
          productPackType: this.dialogForm.productPackType,
          productCategory: this.dialogForm.productCategory,
          productSpecifications: this.dialogForm.productSpecifications,
        };
        updateProduct(data, token)
          .then((response) => {
            console.log(response.data);
            ElMessage.success("Product updated successfully");
            this.fetchProductsData();
          })
          .catch((error) => {
            console.log(error);
            ElMessage.error("Failed to update product");
          });
      } else {
        // Add product
        const token = localStorage.getItem("token");
        const data = {
          productName: this.dialogForm.productName,
          productPrice: this.dialogForm.productPrice,
          productDescription: this.dialogForm.productDescription,
          productType: this.dialogForm.productType,
          productWeight: this.dialogForm.productWeight,
          productBestPrice: this.dialogForm.productBestPrice,
          productYearOfProduction: this.dialogForm.productYearOfProduction,
          productCost: this.dialogForm.productCost,
          productLocation: this.dialogForm.productLocation,
          productPackType: this.dialogForm.productPackType,
          productCategory: this.dialogForm.productCategory,
          productSpecifications: this.dialogForm.productSpecifications,
        };
        console.log("Add product", this.dialogForm);
        addProduct(data, token)
          .then((response) => {
            console.log(response.data);
            ElMessage.success("Product added successfully");
            this.fetchProductsData();
          })
          .catch((error) => {
            console.log(error);
            ElMessage.error("Failed to add product");
          });
      }
      this.dialogFormVisible = false;
    },
    handleUpload(id) {
      console.log("Upload image", id);
      this.dialogImageForm.id = id;
      this.getImages(id);
      this.dialogImageVisible = true;
    },
    getImages(id) {
      this.imageDialogLoading = true;
      this.coverImage = [];
      const token = localStorage.getItem("token");
      getProductImages(id, token)
        .then((response) => {
          this.dialogImageForm.fileList = response.data.images.map((image) => {
            return {
              filename: image.filename,
              url: this.HOST + image.url,
              productId: image.productId,
            };
          });
          this.coverImage = response.data.coverImage
            ? [
                {
                  filename: response.data.coverImage.filename,
                  url: this.HOST + response.data.coverImage,
                  productId: response.data.coverImage.productId,
                },
              ]
            : [];
          console.log("Images", this.dialogImageForm.fileList);
          console.log("Cover", response.data.coverImage);
          this.imageDialogLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.imageDialogLoading = false;
        });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleChange(file, fileList) {
      console.log("Change", file);
      this.dialogImageForm.fileList = fileList;
    },
    handleRemove(file, fileList) {
      this.dialogImageForm.fileList = fileList;
      const token = localStorage.getItem("token");
      const data = {
        productId: file.productId,
        imageName: file.filename,
      };
      deleteProductImage(data, token)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    uploadProductImage() {
      this.imageDialogLoading = true;
      console.log("File list", this.dialogImageForm.fileList);
      const uploadPromises = this.dialogImageForm.fileList
        .filter((file) => file.status === "ready")
        .map((file) => {
          const formData = new FormData();
          formData.append("file", file.raw);
          formData.append("id", this.dialogImageForm.id);
          const token = localStorage.getItem("token");
          uploadImage(formData, token)
            .then((response) => {
              console.log(response.data);
              this.fetchProductsData();
              this.imageDialogLoading = false;
            })
            .catch((error) => {
              console.log(error);
              this.imageDialogLoading = false;
            });
        });
      console.log("Upload promises", uploadPromises);
    },
    uploadProductCover() {
      this.imageDialogLoading = true;
      console.log("Cover Image", this.coverImage);
      // this.dialogImageVisible = false;
      console.log(this.dialogImageForm.id);
      const formData = new FormData();
      formData.append("file", this.coverImage[0].raw);
      formData.append("productId", this.dialogImageForm.id);
      const token = localStorage.getItem("token");
      uploadCover(formData, token)
        .then((response) => {
          console.log(response.data);
          this.fetchProductsData();
          this.imageDialogLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.imageDialogLoading = false;
        });
    },
    handleCoverExceed(files, fileList) {
      console.log("Exceed", files, fileList);
      this.$refs.uploadCover.clearFiles();
      const file = files[0];
      file.uid = genFileId();
      this.$refs.uploadCover.handleStart(file);
    },
    handleTabClick(tab) {
      if (
        tab.paneName === "featuredProductsManagement" &&
        this.activeTab !== "featuredProductsManagement"
      ) {
        getFeaturedProducts().then((response) => {
          response.data.products.forEach((element) => {
            this.featuredProductsIDList.push(element.id);
          });
        });
      }
    },
    resetFeaturedProducts() {
      this.featuredProductsIDList = [];
      getFeaturedProducts().then((response) => {
        response.data.products.forEach((element) => {
          this.featuredProductsIDList.push(element.id);
        });
      });
    },
    saveFeaturedProducts() {
      console.log("Save", this.featuredProductsIDList);
      const token = localStorage.getItem("token");
      const data = {
        featuredProducts: this.featuredProductsIDList,
      };
      updateFeaturedProducts(data, token).then((response) => {
        console.log(response.data);
      });
    },
    addSpecification() {
      // Add a new empty specification object
      this.dialogForm.productSpecifications.push({
        id: "",
        title: "",
        weight: "",
        price: "",
        bestPrice: "",
      });
    },
    removeSpecification(index) {
      // Remove the specified product specification
      this.dialogForm.productSpecifications.splice(index, 1);
    },
  },
};
</script>
