<template>
  <el-config-provider :locale="localeState.appLocale">
    <router-view></router-view>
  </el-config-provider>
</template>

<script>
import { localeState } from "./locale.js";
export default {
  name: "App",
  components: {},
  data() {
    return {
      localeState,
    };
  },
};
</script>

<style>
/* Import Google Fonts in Vue component */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

#app {
  /* font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, Avenir,
    Helvetica, Arial; */
  font-family: Inter, "Helvetica Neue", Helvetica, "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: auto;
}

/* Remove default scrollbars */
::-webkit-scrollbar {
  display: none;
}

.el-footer {
  height: 100%;
}

.el-header {
  padding: 0;
}
.divider {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
