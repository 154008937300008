import { reactive } from "vue";
import zhcn from "/src/locale/lang/zh-cn.js";
import en from "/src/locale/lang/en.js";

export const localeState = reactive({
  language: "en",
  get appLocale() {
    switch (this.language) {
      case "zh-cn":
        return zhcn;
      case "en":
        return en;
      default:
        return en;
    }
  },
  setLanguage(newLanguage) {
    this.language = newLanguage;
  },
});
