<template>
  <el-container>
    <el-main>
      <h1>Order Management</h1>
      <el-button
        type="primary"
        round
        @click="fetchOrdersData"
        style="margin-bottom: 20px"
        >Fetch Order Data</el-button
      >
      <div>
        <!-- <el-tag
          v-for="(status, index) in getStatus()"
          :key="index"
          type="success"
          style="margin-bottom: 10px"
          >{{ status }}</el-tag
        > -->
      </div>
      <el-table
        :data="orders"
        style="width: 100%"
        max-height="500"
        stripe
        border
        scrollbar-always-on
        ref="orderTable"
      >
        <el-table-column
          prop="id"
          label="Order ID"
          width="180"
          fixed
          sortable
        ></el-table-column>
        <el-table-column prop="userId" label="User ID" width="180" sortable>
        </el-table-column>
        <el-table-column
          prop="orderPlacedTime"
          label="Order Placed Time"
          width="180"
          sortable
        >
        </el-table-column>
        <el-table-column
          prop="orderStatus"
          label="Order Status"
          width="180"
          sortable
          :filters="getStatus()"
          :filter-method="filterHandler"
        >
        </el-table-column>
        <el-table-column
          prop="orderTotalPrice"
          label="Total Price"
          width="180"
          sortable
        >
        </el-table-column>
        <el-table-column prop="orderItemsInfo" label="Order Items" width="180">
          <template #default="{ row }">
            <span>
              <div v-for="(item, index) in row.orderItemInfo" :key="index">
                <el-tag type="info">
                  {{ item.selectedSpec.weight }}g {{ item.productName }} *
                  {{ item.quantity }}
                </el-tag>
              </div>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orderPaymentAmount"
          label="Payment Amount"
          width="180"
        >
        </el-table-column>
        <el-table-column prop="orderPaymentRef" label="Payment Ref" width="180">
        </el-table-column>
        <el-table-column fixed="right" label="Operations" width="180">
          <template v-slot="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row)"
              >Edit</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="handleDelete(scope.$index, scope.row)"
              disabled
              >Delete</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        title="Update Order Status"
        v-model="orderDetailsVisible"
        width="70%"
        @close="orderDetailsVisible = false"
      >
        <el-divider>Billing Details</el-divider>

        <el-descriptions title="Billing Details" :column="4" border>
          <el-descriptions-item label="First Name">
            {{ this.orderBillingAddress.orderBillingFirstName }}
          </el-descriptions-item>
          <el-descriptions-item label="Last Name">
            {{ this.orderBillingAddress.orderBillingLastName }}
          </el-descriptions-item>
          <el-descriptions-item label="Phone">
            {{ this.orderBillingAddress.orderBillingPhone }}
          </el-descriptions-item>
          <el-descriptions-item label="Email">
            {{ this.orderBillingAddress.orderBillingEmail }}
          </el-descriptions-item>
          <el-descriptions-item label="Street">
            {{ this.orderBillingAddress.orderBillingAddressStreet }}
          </el-descriptions-item>
          <el-descriptions-item label="City">
            {{ this.orderBillingAddress.orderBillingAddressCity }}
          </el-descriptions-item>
          <el-descriptions-item label="State">
            {{ this.orderBillingAddress.orderBillingAddressState }}
          </el-descriptions-item>
          <el-descriptions-item label="zip">
            {{ this.orderBillingAddress.orderBillingAddressZip }}
          </el-descriptions-item>
        </el-descriptions>

        <el-divider>Shipping Details</el-divider>

        <el-descriptions title="Shipping Details" :column="4" border>
          <el-descriptions-item label="First Name">
            {{ this.orderShippingAddress.orderShippingFirstName }}
          </el-descriptions-item>
          <el-descriptions-item label="Last Name">
            {{ this.orderShippingAddress.orderShippingLastName }}
          </el-descriptions-item>
          <el-descriptions-item label="Phone">
            {{ this.orderShippingAddress.orderShippingPhone }}
          </el-descriptions-item>
          <el-descriptions-item label="Email">
            {{ this.orderShippingAddress.orderShippingEmail }}
          </el-descriptions-item>
          <el-descriptions-item label="Street">
            {{ this.orderShippingAddress.orderShippingAddressStreet }}
          </el-descriptions-item>
          <el-descriptions-item label="City">
            {{ this.orderShippingAddress.orderShippingAddressCity }}
          </el-descriptions-item>
          <el-descriptions-item label="State">
            {{ this.orderShippingAddress.orderShippingAddressState }}
          </el-descriptions-item>
          <el-descriptions-item label="zip">
            {{ this.orderShippingAddress.orderShippingAddressZip }}
          </el-descriptions-item>
        </el-descriptions>
        <el-form
          style="padding-top: 20px"
          :model="orderStatusForm"
          label-width="150px"
          ref="orderStatusForm"
          :rules="orderStatusRules"
        >
          <el-form-item label="Order ID" prop="orderId">
            <el-input v-model="orderStatusForm.orderId" disabled></el-input>
          </el-form-item>
          <el-form-item label="Order Status" prop="status">
            <el-select v-model="orderStatusForm.status" placeholder="Select">
              <el-option label="Pending" value="Pending"></el-option>
              <el-option label="Shipped" value="Shipped"></el-option>
              <el-option label="Delivered" value="Delivered"></el-option>
              <el-option label="Cancelled" value="Cancelled"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button @click="orderDetailsVisible = false">Cancel</el-button>
          <el-button type="primary" @click="updateOrderStatus"
            >Update</el-button
          >
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import { getOrdersData, updateOrderStatus } from "@/api/admin";
import { ElLoading, ElMessage } from "element-plus";

export default {
  data() {
    return {
      orders: [],
      orderDetailsVisible: false,
      orderStatusForm: {
        orderId: "",
        status: "",
      },
      orderBillingAddress: {},
      orderShippingAddress: {},
      orderStatusRules: {
        status: [
          {
            required: true,
            message: "Please select order status",
            trigger: "change",
          },
        ],
      },
      svg: `
        <path class="path" d="
          M 30 15
          L 28 17
          M 25.61 25.61
          A 15 15, 0, 0, 1, 15 30
          A 15 15, 0, 1, 1, 27.99 7.5
          L 15 15
        " style="stroke-width: 4px; fill: rgba(0, 0, 0, 0)"/>
      `,
    };
  },
  created() {},
  methods: {
    fetchOrdersData() {
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "Fetching Order Data...",
        spinner: this.svg,
        background: "rgba(0, 0, 0, 0.7)",
      });
      const token = localStorage.getItem("token");
      getOrdersData(token)
        .then((response) => {
          this.orders = response.data;
          console.log(response.data);
          loadingInstance.close();
        })
        .catch((error) => {
          console.log(error);
          loadingInstance.close();
        });
    },
    handleEdit(row) {
      this.orderDetailsVisible = true;
      this.orderStatusForm.orderId = row.id;
      this.orderStatusForm.status = row.orderStatus;
      this.orderShippingAddress = {
        orderShippingFirstName: row.orderShippingFirstName,
        orderShippingLastName: row.orderShippingLastName,
        orderShippingEmail: row.orderShippingEmail,
        orderShippingPhone: row.orderShippingPhone,
        orderShippingAddressCity: row.orderShippingAddressCity,
        orderShippingAddressState: row.orderShippingAddressState,
        orderShippingAddressStreet: row.orderShippingAddressStreet,
        orderShippingAddressZip: row.orderShippingAddressZip,
      };
      this.orderBillingAddress = {
        orderBillingFirstName: row.orderBillingFirstName,
        orderBillingLastName: row.orderBillingLastName,
        orderBillingEmail: row.orderBillingEmail,
        orderBillingPhone: row.orderBillingPhone,
        orderBillingAddressCity: row.orderBillingAddressCity,
        orderBillingAddressState: row.orderBillingAddressState,
        orderBillingAddressStreet: row.orderBillingAddressStreet,
        orderBillingAddressZip: row.orderBillingAddressZip,
      };
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    updateOrderStatus() {
      const token = localStorage.getItem("token");
      const data = {
        order_id: this.orderStatusForm.orderId,
        order_status: this.orderStatusForm.status,
      };
      const loadingInstance = ElLoading.service({
        lock: true,
        text: "Updating Order Status...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      updateOrderStatus(data, token)
        .then((response) => {
          console.log(response.data);
          this.orderDetailsVisible = false;
          this.fetchOrdersData();
          loadingInstance.close();
          ElMessage.success("Order status updated successfully");
        })
        .catch((error) => {
          console.log(error);
          loadingInstance.close();
        });
    },
    getStatus() {
      const statusSet = new Set();

      this.orders.forEach((order) => {
        statusSet.add(order.orderStatus); // Add each orderStatus to the Set
      });

      const uniqueStatus = Array.from(statusSet);

      const filters = uniqueStatus.map((status) => {
        return { text: status, value: status };
      });

      return filters;
    },
    filterHandler(value, row) {
      return row.orderStatus === value;
    },
  },
};
</script>
