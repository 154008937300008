import request from "../utils/request";

export function getUserData(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axoisAuthGet("/pujing/getUserData", config);
}

export function getUserOrders(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axoisAuthGet("/pujing/getUserOrders", config);
}

export function addUserAddress(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthPost("/pujing/addUserAddress", jsondata, config);
}

export function getUserAddresses(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axoisAuthGet("/pujing/getUserAddresses", config);
}

export function updateUserAddress(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthPost("/pujing/updateUserAddress", jsondata, config);
}

export function deleteUserAddress(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthDelete("/pujing/deleteUserAddress", jsondata, config);
}

export function getUserRole(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axoisAuthGet("/pujing/getUserRole", config);
}

export function getAccountData(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axoisAuthGet("/pujing/getAccountData", config);
}

export function updateAccountData(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthPost("/pujing/updateAccountData", jsondata, config);
}
