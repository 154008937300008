export default {
  // 通用
  name: "zh-cn",
  common: {
    test: "测试",
    loading: "加载中...",
    success: "操作成功",
    error: "操作失败",
    confirm: "确定",
    cancel: "取消",
    save: "保存",
    delete: "删除",
    edit: "编辑",
    add: "新增",
    search: "搜索",
    reset: "重置",
  },
  navbar: {
    home: "主页",
    tea: "茶叶",
    teaware: "茶具",
    teaGift: "茶礼",
    teaInfo: "茶信息",
    teaList: "茶列表",
    teaDetail: "茶详情",
    cart: "购物车",
    login: "登录",
    loggedIn: "已登录 ",
    account: "账户",
    logout: "登出",
  },
  product: {
    teaTitle: "茶叶",
    teawareTitle: "茶具",
    teaGiftTitle: "茶礼",
    teaDescription: "我们的茶叶",
    teawareDescription: "我们的茶具",
    teaGiftDescription: "我们的茶礼",
    openFilter: "打开筛选",
    filter: "筛选",
    resetFilter: "重设",
    multiSelect: "多选",
    singleSelect: "单选",
    viewDetail: "详情",
  },
  home: {
    title: "欢迎来到我们的茶店",
    category: "分类",
    products: "精选产品",
    story: "我们的故事",
  },
  footer: {
    quickLinks: "快速链接",
    company: "公司",
    contact: "联系我们",
  },
  cart: {
    title: "购物车",
    empty: "您的购物车是空的，快去购物吧！",
    checkout: "去结算",
    productName: "商品名称",
    price: "商品价格",
    bestPrice: "最低价格",
    total: "总价",
    quantity: "数量",
    weight: "重量",
    actions: "操作",
    grandTotal: "总计",
    subTotal: "小计",
    remove: "移除",
  },
  productDetail: {
    priceFrom: "价格起",
    quantity: "数量",
    specifications: "规格",
    description: "描述",
    per: "每",
    g: "克",
    selectedSpec: "已选规格",
    totalPrice: "总价",
    addToCart: "加入购物车",
    buyNow: "立即购买",
    productDetails: "商品详情",
    productPhotos: "商品图片",
    shippingInfo: "物流信息",
    productPackType: "包装类型",
    productWeight: "商品重量",
    productLocation: "商品所在地",
    productYearOfProduction: "生产年份",
    productType: "商品类型",
  },
  account: {
    details: "账户详情",
    username: "用户名",
    userId: "用户 ID",
    customer: "客户",
    email: "电子邮件",
    phone: "电话",
    save: "保存",
  },
  addresses: {
    title: "地址",
    firstName: "名字",
    lastName: "姓氏",
    phone: "电话",
    email: "电子邮件",
    actions: "操作",
    edit: "编辑",
    delete: "删除",
    duplicate: "复制",
    addNew: "添加新地址",
    editAddress: "编辑地址",
    street: "街道地址",
    city: "城市",
    state: "州",
    postcode: "邮政编码",
    default: "设为默认地址",
    save: "保存",
    cancel: "取消",
  },
  orders: {
    title: "订单",
    orderId: "订单 ID",
    total: "订单总额",
    allItems: "所有商品",
    status: "状态",
    operations: "操作",
    viewDetails: "查看详情",
    noOrders: "未找到订单",
    details: {
      title: "订单详情",
      placedTime: "下单时间",
      totalPrice: "订单总额",
      paymentAmount: "付款金额",
    },
    billing: "账单详情",
    shipping: "配送详情",
    actions: {
      addToCart: "再次加入购物车",
      trackOrder: "追踪订单",
      emailReceipt: "发送电子收据",
      question: "对订单有疑问？",
    },
  },
  checkout: {
    alreadyHaveAccount: "已经有账号？",
    login: "登录",
    loggedIn: "已登录",
    selectAddress: "选择地址",
    selectAnAddress: "选择一个地址",
    defaultShippingInfo: "默认配送信息",
    firstName: "名字",
    lastName: "姓氏",
    email: "电子邮件",
    phone: "电话",
    shippingAddress: "配送地址",
    applyThisAddress: "使用此地址",
    billingDetails: "账单详情",
    streetAddress: "街道地址",
    city: "城市",
    state: "州",
    postcode: "邮政编码",
    shippingToBillingAddress: "将配送地址设为账单地址",
    shippingDetails: "配送详情",
    orderSummary: "订单摘要",
    checkoutButton: "结账",
  },
  login: {
    title: "登录",
    username: "用户名",
    password: "密码",
    confirmPassword: "确认密码",
    email: "电子邮件",
    login: "登录",
    loginSuccess: "登录成功",
    loginSuccessMessage: "您已成功登录",
    forgotPassword: "忘记密码？",
    register: "注册",
    registerTitle: "注册",
    registerSuccess: "注册成功",
    registerSuccessMessage: "您已成功注册",
    inputRules: {
      required: "此字段为必填项",
      email: "请输入有效的电子邮件地址",
      username: "请输入有效的用户名",
      password: "请输入有效的密码",
      confirmPassword: "请输入确认密码",
      passwordMatch: "密码不匹配",
    },
  },
};
