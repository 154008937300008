<template>
  <el-container
    style="min-height: 100vh; display: flex; flex-direction: column"
  >
    <el-header class="sticky-div">
      <NavBar :activeIndex="activeIndex" @menu-select="handleMenuSelect" />
    </el-header>
    <el-main class="main" style="flex: 1">
      <div class="form-container">
        <div class="form-section">
          <h2>{{ appLocale.login.title }}</h2>
          <el-form
            :model="loginForm"
            :rules="loginRules"
            ref="loginForm"
            @submit.prevent="login"
          >
            <el-form-item label="username or Email" prop="loginIdentifier">
              <el-input v-model="loginForm.loginIdentifier" />
            </el-form-item>
            <el-form-item
              :label="appLocale.login.password"
              prop="loginPassword"
            >
              <el-input
                type="password"
                v-model="loginForm.loginPassword"
                @keyup.enter="submitLoginForm"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitLoginForm">{{
                appLocale.login.login
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="form-section">
          <h2>{{ appLocale.login.register }}</h2>
          <el-form
            :model="registerForm"
            :rules="registerRules"
            ref="registerForm"
            @submit.prevent="register"
          >
            <el-form-item
              :label="appLocale.login.username"
              prop="registerUsername"
            >
              <el-input v-model="registerForm.registerUsername" />
            </el-form-item>
            <el-form-item :label="appLocale.login.email" prop="registerEmail">
              <el-input v-model="registerForm.registerEmail" />
            </el-form-item>
            <el-form-item
              :label="appLocale.login.password"
              prop="registerPassword"
            >
              <el-input
                type="password"
                v-model="registerForm.registerPassword"
              />
            </el-form-item>
            <el-form-item
              :label="appLocale.login.confirmPassword"
              prop="registerConfirmPassword"
            >
              <el-input
                type="password"
                v-model="registerForm.registerConfirmPassword"
                @keyup.enter="submitLoginForm"
              />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitRegisterForm">{{
                appLocale.login.register
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-main>
    <el-footer>
      <footerCom />
    </el-footer>
  </el-container>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import footerCom from "@/components/footerCom.vue";
import { login, register } from "@/api/login.js";
import { ElLoading, ElNotification } from "element-plus";
import { localeState } from "@/locale.js";
export default {
  components: {
    NavBar,
    footerCom,
  },
  computed: {
    appLocale() {
      return localeState.appLocale;
    },
  },
  data() {
    return {
      activeIndex: "1", // Adjust as necessary
      loginForm: {
        loginIdentifier: "",
        loginPassword: "",
      },
      registerForm: {
        registerUsername: "",
        registerEmail: "",
        registerPassword: "",
        registerConfirmPassword: "",
      },
      loginRules: {
        loginIdentifier: [
          {
            required: true,
            message: "Please input username/email",
            trigger: "blur",
          },
        ],
        loginPassword: [
          { required: true, message: "Please input password", trigger: "blur" },
        ],
      },
      registerRules: {
        registerUsername: [
          { required: true, message: "Please input username", trigger: "blur" },
        ],
        registerEmail: [
          { required: true, message: "Please input email", trigger: "blur" },
        ],
        registerPassword: [
          { required: true, message: "Please input password", trigger: "blur" },
        ],
        registerConfirmPassword: [
          {
            required: true,
            message: "Please confirm password",
            trigger: "blur",
          },
          { validator: this.validateConfirmPassword, trigger: "blur" },
        ],
      },
      loginLoading: null,
      loginNotification: null,
    };
  },
  methods: {
    validateConfirmPassword(rule, value, callback) {
      if (value !== this.registerForm.registerPassword) {
        callback(new Error("Passwords do not match"));
      } else {
        callback();
      }
    },
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          const userCredentials = {
            identifier: this.loginForm.loginIdentifier,
            password: this.loginForm.loginPassword,
          };
          this.loginLoading = ElLoading.service({
            lock: true,
            text: "Logging in...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          login(userCredentials).then((response) => {
            this.loginLoading.close();
            if (response.data.token) {
              this.$store.mutations.loggedin(
                response.data.token,
                response.data.username
              );
              ElNotification({
                title: "Login Successful",
                message: "Welcome back! " + this.loginForm.loginUsername,
                type: "success",
                offset: 70,
              });
              if (response.data.isAdmin) {
                this.$router.push("/admin");
              } else {
                this.$router.push("/");
              }
            } else {
              this.$store.mutations.logout();
              this.loginLoading.close();
              ElNotification({
                title: "Login Failed",
                message: "Invalid username or password",
                type: "error",
                offset: 70,
              });
            }
          });
        }
      });
    },
    register() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          if (
            this.registerForm.registerPassword !==
            this.registerForm.registerConfirmPassword
          ) {
            ElNotification({
              title: "Registration Failed",
              message: "Passwords do not match",
              type: "error",
              offset: 70,
            });
            return;
          }
          const newUser = {
            username: this.registerForm.registerUsername,
            email: this.registerForm.registerEmail,
            password: this.registerForm.registerPassword,
          };
          register(newUser).then((response) => {
            if (response.data.token) {
              this.$store.mutations.loggedin(
                response.data.token,
                response.data.username
              );
              ElNotification({
                title: "Registration Successful",
                message: "Welcome! " + response.data.username,
                type: "success",
                offset: 70,
              });
              console.log("User registered:", response.data);
              this.$router.push("/");
            } else {
              ElNotification({
                title: "Registration Failed",
                message:
                  response.data.message ||
                  "Registration failed, please try again",
                type: "error",
                offset: 70,
              });
            }
          });
        }
      });
    },
    submitLoginForm() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.login();
        }
      });
    },
    submitRegisterForm() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.register();
        }
      });
    },
  },
};
</script>

<style scoped>
.sticky-div {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.form-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  flex-wrap: wrap;
}

.form-section {
  max-width: 400px;
  width: 100%;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}
</style>
