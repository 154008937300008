export default {
  name: "en",
  common: {
    test: "Test",
    loading: "Loading...",
  },
  navbar: {
    home: "Home",
    tea: "Tea",
    teaware: "Teaware",
    teaGift: "TeaGift",
    teaInfo: "TeaInfo",
    teaList: "TeaList",
    teaDetail: "TeaDetail",
    cart: "Cart",
    login: "Login",
    loggedIn: "Logged In as ",
    account: "Account",
    logout: "Logout",
  },
  home: {
    title: "Welcome to TeaShop",
    category: "Our Categories",
    products: "Featured Products",
    story: "Our Story",
  },
  product: {
    teaTitle: "Tea",
    teawareTitle: "Teaware",
    teaGiftTitle: "TeaGift",
    teaDescription:
      "Explore our wide selection of tea varieties, from classic black tea to exotic green tea. Our tea is carefully sourced from the best tea gardens around the world and carefully blended to bring out the best flavors and aromas.",
    teawareDescription:
      "Discover our exquisite teaware collection, featuring beautifully crafted tea sets, teapots, and tea cups. Our teaware is designed to enhance your tea-drinking experience and add a touch of elegance to your tea time.",
    teaGiftDescription:
      "Give the gift of tea to your loved ones with our exquisite tea gift sets. Our tea gift sets include a selection of our finest tea leaves, along with a beautifully crafted teaware set. Perfect for any occasion, our tea gift sets are sure to bring joy and relaxation to the recipient.",
    filter: "Filter",
    openFilter: "Open Filter",
    resetFilter: "Reset Filter",
    multiSelect: "Multi Select",
    singleSelect: "Single Select",
    viewDetail: "View Detail",
  },
  footer: {
    quickLinks: "Quick Links",
    company: "Company",
    contact: "Contact",
  },
  cart: {
    title: "Shopping Cart",
    empty: "Your cart is empty, go shopping now!",
    checkout: "Proceed to Checkout",
    productName: "Product Name",
    price: "Product Price",
    bestPrice: "Best Price",
    total: "Total",
    quantity: "Quantity",
    weight: "Weight",
    actions: "Actions",
    grandTotal: "Grand Total",
    subTotal: "Sub Total",
    remove: "Remove",
  },
  productDetail: {
    priceFrom: "Price From",
    quantity: "Quantity",
    specifications: "Specifications",
    description: "Description",
    per: "per",
    g: "g",
    selectedSpec: "Selected Specification",
    totalPrice: "Total Price",
    addToCart: "Add to Cart",
    buyNow: "Buy Now",
    productDetails: "Product Details",
    productPhotos: "Product Photos",
    shippingInfo: "Shipping Info",
    productPackType: "Product Pack Type",
    productWeight: "Product Weight",
    productLocation: "Product Location",
    productYearOfProduction: "Product Year of Production",
    productType: "Product Type",
  },
  account: {
    details: "Account Details",
    username: "Username",
    userId: "User ID",
    customer: "Customer",
    email: "Email",
    phone: "Phone",
    save: "Save",
  },
  addresses: {
    title: "Addresses",
    firstName: "First Name",
    lastName: "Last Name",
    phone: "Phone",
    email: "Email",
    actions: "Actions",
    edit: "Edit",
    delete: "Delete",
    duplicate: "Duplicate",
    addNew: "Add New Address",
    editAddress: "Edit Address",
    street: "Street Address",
    city: "City",
    state: "State",
    postcode: "Postcode",
    default: "Make this my default address",
    save: "Save",
    cancel: "Cancel",
  },
  orders: {
    title: "Orders",
    orderId: "Order ID",
    total: "Order Total",
    allItems: "All Items",
    status: "Status",
    operations: "Operations",
    viewDetails: "View Details",
    noOrders: "No orders found",
    details: {
      title: "Order Details",
      placedTime: "Order Placed Time",
      totalPrice: "Order Total Price",
      paymentAmount: "Order Payment Amount",
    },
    billing: "Billing Details",
    shipping: "Shipping Details",
    actions: {
      addToCart: "Add to cart Again",
      trackOrder: "Track Order",
      emailReceipt: "Email receipt",
      question: "Have question about this order?",
    },
  },
  checkout: {
    alreadyHaveAccount: "Already have an account?",
    login: "Login",
    loggedIn: "Logged in",
    selectAddress: "Select Address",
    selectAnAddress: "Select an address",
    defaultShippingInfo: "Default Shipping Info",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone",
    shippingAddress: "Shipping Address",
    applyThisAddress: "Apply This Address",
    billingDetails: "Billing Details",
    streetAddress: "Street Address",
    city: "City",
    state: "State",
    postcode: "Postcode",
    shippingToBillingAddress: "Shipping to Billing Address",
    shippingDetails: "Shipping Details",
    orderSummary: "Order Summary",
    checkoutButton: "Check Out",
  },
  login: {
    title: "Login",
    username: "Username",
    password: "Password",
    confirmPassword: "Confirm Password",
    email: "Email",
    login: "Login",
    loginSuccess: "Login Success",
    loginSuccessMessage: "You have successfully logged in",
    forgotPassword: "Forgot Password?",
    register: "Register",
    registerTitle: "Register",
    registerSuccess: "Register Success",
    registerSuccessMessage: "You have successfully registered",
    inputRules: {
      required: "This field is required",
      email: "Please enter a valid email address",
      username: "Please enter a valid username",
      password: "Please enter a valid password",
      confirmPassword: "Please enter a valid confirm password",
      passwordMatch: "Passwords do not match",
    },
  },
};
