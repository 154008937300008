<template>
  <div>
    <p>Current Locale: {{ appLocale.common.test }}</p>
  </div>
</template>

<script>
import { localeState } from "@/locale.js";
export default {
  computed: {
    appLocale() {
      return localeState.appLocale;
    },
  },
};
</script>
