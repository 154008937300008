import { createRouter, createWebHistory } from "vue-router";
import HomeView from "./views/HomeView.vue";
import CheckoutView from "./views/CheckoutView.vue";
import ProductDetail from "./views/ProductDetail.vue";
import LoginView from "./views/LoginView.vue";
import AccountView from "./views/AccountView.vue";
import TeaInfoView from "./views/TeaInfoView.vue";
import FAQView from "./views/FAQView.vue";
import AdminView from "./views/AdminView.vue";
import ProductManagement from "./admin/ProductManagement.vue";
import OrderManagement from "./admin/OrderManagement.vue";
import CustomerManagement from "./admin/CustomerManagement.vue";
import CSM from "./admin/CSM.vue";
import WebsiteConfig from "./admin/WebsiteConfig.vue";
import DashboardView from "./admin/DashboardView.vue";
import OrderView from "./views/OrderView.vue";
import store from "./store";
import TeaList from "./tea-info/TeaList.vue";
import TeaInfo from "./tea-info/TeaInfo.vue";
import TeaDetail from "./tea-info/TeaDetail.vue";

import TeaProduct from "./views/TeaProduct.vue";
import TeawareView from "./views/TeawareView.vue";
import TeaGift from "./views/TeaGift.vue";
import TestView from "./views/TestView.vue";
const routes = [
  { path: "/", component: HomeView },
  { path: "/tea", component: TeaProduct },
  { path: "/teaware", component: TeawareView },
  { path: "/tea-gift", component: TeaGift },
  { path: "/checkout", component: CheckoutView },
  {
    path: "/tea-products/:id",
    name: "ProductDetail",
    component: ProductDetail,
  },
  { path: "/login", component: LoginView },
  { path: "/account", component: AccountView },

  { path: "/faq", component: FAQView },
  { path: "/order", name: "OrderDone", component: OrderView },
  {
    path: "/admin",
    component: AdminView,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: DashboardView },
      { path: "product-management", component: ProductManagement },
      { path: "order-management", component: OrderManagement },
      { path: "customer-management", component: CustomerManagement },
      { path: "csm", component: CSM },
      { path: "website-config", component: WebsiteConfig },
    ],
  },
  {
    path: "/tea-info",
    component: TeaInfoView,
    children: [
      {
        path: "tea-list",
        component: TeaList,
      },
      {
        path: "",
        component: TeaInfo,
      },
      {
        path: "tea-detail",
        component: TeaDetail,
      },
    ],
  },
  { path: "/test", component: TestView },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token");

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      next("/login");
    } else {
      if (!store.state.user) {
        await store.actions.fetchUser();
      }

      if (
        to.matched.some((record) => record.meta.requiresAdmin) &&
        store.state.user.data !== "admin"
      ) {
        next("/");
      } else {
        next();
      }
    }
  } else {
    next();
  }
});
export default router;
