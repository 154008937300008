<template>
  <div class="order-done">
    <el-card>
      <div class="clearfix">
        <span>Order Confirmation</span>
      </div>
      <div class="order-details">
        <el-result
          icon="success"
          title="Order Placed Successfully!"
          subTitle="Thank you for your purchase. Your order has been placed successfully."
        >
          <template #extra>
            <el-button type="primary" @click="goToOrders"
              >View Orders</el-button
            >
            <el-button @click="continueShopping">Continue Shopping</el-button>
          </template>
        </el-result>

        <el-divider>Order Details</el-divider>

        <el-descriptions title="Order Details" :column="4" border>
          <el-descriptions-item label="Order ID">
            {{ order.orderID }}
          </el-descriptions-item>
          <el-descriptions-item label="Order Placed Time">
            {{ order.orderPlacedTime }}
          </el-descriptions-item>
          <el-descriptions-item label="Order Total Price">
            {{ order.orderTotal }}
          </el-descriptions-item>
          <el-descriptions-item label="Order Payment Amount">
            {{ order.orderPaymentAmount }}
          </el-descriptions-item>
        </el-descriptions>

        <el-divider>All Items</el-divider>

        <el-table
          :data="order.processingProduct"
          style="width: 100%"
          stripe
          border
        >
          <el-table-column prop="productName" label="Product Name">
          </el-table-column>
          <el-table-column prop="quantity" label="Quantity"> </el-table-column>
          <el-table-column
            prop="selectedSpec.price"
            label="Price"
          ></el-table-column>
          <el-table-column
            prop="selectedSpec.bestPrice"
            label="Best Price"
          ></el-table-column>
        </el-table>

        <el-divider>Billing Details</el-divider>

        <el-descriptions title="Billing Details" :column="4" border>
          <el-descriptions-item label="First Name">
            {{ order.orderAddresses.orderBillingFirstName }}
          </el-descriptions-item>
          <el-descriptions-item label="Last Name">
            {{ order.orderAddresses.orderBillingLastName }}
          </el-descriptions-item>
          <el-descriptions-item label="Phone">
            {{ order.orderAddresses.orderBillingPhone }}
          </el-descriptions-item>
          <el-descriptions-item label="Email">
            {{ order.orderAddresses.orderBillingEmail }}
          </el-descriptions-item>
          <el-descriptions-item label="Street">
            {{ order.orderAddresses.orderBillingAddressStreet }}
          </el-descriptions-item>
          <el-descriptions-item label="City">
            {{ order.orderAddresses.orderBillingAddressCity }}
          </el-descriptions-item>
          <el-descriptions-item label="State">
            {{ order.orderAddresses.orderBillingAddressState }}
          </el-descriptions-item>
          <el-descriptions-item label="zip">
            {{ order.orderAddresses.orderBillingAddressZip }}
          </el-descriptions-item>
        </el-descriptions>

        <el-divider>Shipping Details</el-divider>

        <el-descriptions title="Shipping Details" :column="4" border>
          <el-descriptions-item label="First Name">
            {{ order.orderAddresses.orderShippingFirstName }}
          </el-descriptions-item>
          <el-descriptions-item label="Last Name">
            {{ order.orderAddresses.orderShippingLastName }}
          </el-descriptions-item>
          <el-descriptions-item label="Phone">
            {{ order.orderAddresses.orderShippingPhone }}
          </el-descriptions-item>
          <el-descriptions-item label="Email">
            {{ order.orderAddresses.orderShippingEmail }}
          </el-descriptions-item>
          <el-descriptions-item label="Street">
            {{ order.orderAddresses.orderShippingAddressStreet }}
          </el-descriptions-item>
          <el-descriptions-item label="City">
            {{ order.orderAddresses.orderShippingAddressCity }}
          </el-descriptions-item>
          <el-descriptions-item label="State">
            {{ order.orderAddresses.orderShippingAddressState }}
          </el-descriptions-item>
          <el-descriptions-item label="zip">
            {{ order.orderAddresses.orderShippingAddressZip }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-card>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
export default {
  data() {
    return {
      order: {
        orderId: "",
        orderPlacedTime: "",
        orderTotalPrice: "",
        orderPaymentAmount: "",
        orderPaymentRef: "",
        orderShippingAddress: "",
        orderBillingAddress: "",
        orderStatus: "",
        orderBuyerInfo: [],
        orderItemInfo: [],
      },
    };
  },
  created() {
    const orderData = sessionStorage.getItem("orderData");
    if (orderData) {
      this.order = JSON.parse(orderData);
      console.log(this.order);
    } else {
      ElMessage.error("No order data found");
    }
  },
  methods: {
    goToOrders() {
      this.$router.push("/orders");
    },
    continueShopping() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.order-done {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}
.order-details {
  margin-top: 20px;
}
</style>
