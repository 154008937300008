<template>
  <div class="home">
    <el-container>
      <el-header>
        <NavBar :activeIndex="activeIndex" @menu-select="handleMenuSelect" />
      </el-header>

      <el-main class="main">
        <div class="cover-image-container">
          <img :src="mainUrl" alt="Tea" class="cover-img" />
          <div class="overlay-text">{{ this.homeData.homePageText }}</div>
        </div>
        <div class="block text-center">
          <span class="demonstration"> OUR TEAS </span>
          <el-carousel height="200px" motion-blur class="carousel">
            <el-carousel-item
              v-for="image in this.homeData.carouselImages"
              :key="image.id"
            >
              <img :src="HOST + image.url" class="cover-img" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <el-divider class="divider"><h2>Our Categories</h2></el-divider>
        <div class="categories">
          <el-row :gutter="50">
            <el-col
              :span="8"
              v-for="item in this.homeData.category"
              :key="item.id"
            >
              <el-card shadow="hover">
                <div class="category-info">
                  <h3>{{ item.title }}</h3>
                  <p>{{ item.description }}</p>
                </div>
                <div class="category-button">
                  <el-button round> Know More</el-button>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <el-divider><h1>Featured Products</h1></el-divider>
        <div class="products">
          <el-row :gutter="50">
            <el-col
              :span="8"
              v-for="product in featuredProducts"
              :key="product.id"
            >
              <el-card shadow="hover">
                <img
                  :src="product.productPreviewImage"
                  :alt="product.productName"
                  class="product-image"
                />
                <div class="product-info">
                  <h3>{{ product.productName }}</h3>
                  <p>{{ product.productDescription }}</p>
                </div>
                <div class="product-button">
                  <el-button
                    type="primary"
                    @click="viewProductDetails(product)"
                  >
                    View Details
                  </el-button>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
        <el-divider><h1>Our Story</h1></el-divider>
        <div class="story" v-if="this.homeData.story">
          <h3>{{ this.homeData.story.title }}</h3>
          <p>{{ this.homeData.story.content }}</p>
        </div>
        <div>
          {{ info }}
        </div>
      </el-main>
      <el-footer>
        <footerCom />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import NavBar from "/src/components/NavBar.vue";
import footerCom from "/src/components/footerCom.vue";
import { getHomeData, getFeaturedProducts } from "/src/api/home.js";
import { HOST } from "/src/utils/var.js";
export default {
  components: {
    NavBar,
    footerCom,
  },
  created() {
    console.log("Fetching home data");
    getHomeData().then((response) => {
      console.log("Home data fetched");
      this.homeData = response.data.homeData;
      this.mainUrl = HOST + this.homeData.homePageImage;
      this.fetchFeaturedProducts();
    });
  },
  data() {
    return {
      activeIndex: "1",
      mainUrl: "",
      carouselUrls: [],
      info: null,
      homeData: [],
      HOST,
      featuredProducts: [],
    };
  },
  methods: {
    viewProductDetails(product) {
      this.$router.push({ name: "ProductDetail", params: { id: product.id } });
    },
    handleMenuSelect(index) {
      // Handle menu item selection
      this.activeIndex = index;
    },
    async fetchFeaturedProducts() {
      try {
        const response = await getFeaturedProducts();
        this.featuredProducts = response.data.products;
        this.featuredProducts.forEach((element) => {
          if (element.productPreviewImage != "") {
            element.productPreviewImage = HOST + element.productPreviewImage;
          } else {
            element.productPreviewImage = "https://via.placeholder.com/150";
          }
        });
        console.log("Featured products fetched:", this.featuredProducts);
      } catch (error) {
        console.error("Error fetching featured products:", error);
      }
    },
  },
  // async beforeCreate() {
  //   console.log("Fetching home data");
  //   await getHomeData().then((response) => {
  //     console.log("Home data fetched");
  //     this.info = response.data;
  //   });
  // },
};
</script>

<style scoped>
.home {
  position: relative;
}

.carousel {
  border-radius: 7px;
}
.grid-content {
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
}
.story {
  margin: 0 auto;
  max-width: 75%;
  margin-bottom: 50px;
}
.products {
  margin: 0 auto;
  max-width: 75%;
}
.product-image {
  width: 100%;
  margin-bottom: 10px;
}

.product-info h3 {
  margin-bottom: 10px;
}

.product-button {
  text-align: center;
  margin-top: 10px;
}

.demonstration {
  color: var(--el-text-color-secondary);
}
.categories {
  margin: 0 auto;
  max-width: 75%;
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.cover-image-container {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
  border-radius: 7px;
}
.cover-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: white;
}

.scrollbar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
}
.el-col {
  margin-bottom: 20px;
  border-radius: 4px;
}
.el-link {
  padding: 5px;
  margin-bottom: 10px;
}

.el-tooltip {
  margin-right: 10px;
}
</style>
