<template>
  <el-container style="min-height: 100vh">
    <el-header>
      <NavBar :activeIndex="activeIndex" @menu-select="handleMenuSelect" />
    </el-header>
    <div class="top-cover">
      <h1 style="font-size: 2.5rem">{{ appLocale.product.teawareTitle }}</h1>
      <p style="max-width: 700px">
        {{ appLocale.product.teawareDescription }}
      </p>
    </div>
    <el-container>
      <!-- Sidebar for Filters -->
      <el-aside width="300px" v-if="!isSmallScreen">
        <h2>{{ appLocale.product.filter }}</h2>
        <div style="display: flex; flex-direction: column; align-items: center">
          <el-button @click="clearFilters">{{
            appLocale.product.resetFilter
          }}</el-button>
          <el-switch
            v-model="singleSelect"
            :active-text="appLocale.product.singleSelect"
            :inactive-text="appLocale.product.multiSelect"
          />
          <el-tag
            v-for="filter in filters"
            :key="filter.id"
            :type="filter.active ? 'success' : 'info'"
            @click="toggleFilter(filter)"
            style="cursor: pointer; margin-bottom: 5px; min-width: 100px"
          >
            {{ filter.name }}
          </el-tag>
        </div>
      </el-aside>

      <el-main style="height: 100%; width: 100%" v-loading="fetchLoading">
        <!-- Button to open the drawer for small screens -->
        <el-button
          @click="drawerVisible = true"
          v-if="isSmallScreen"
          style="margin-bottom: 20px"
        >
          {{ appLocale.product.openFilter }}
        </el-button>

        <!-- Product Rows -->
        <el-row gutter="20">
          <el-col
            v-for="product in filteredProducts"
            :key="product.id"
            :xs="12"
            :sm="12"
            :md="8"
            :lg="6"
            style="justify-content: center"
          >
            <div class="responsive-card-wrapper">
              <el-card class="product-card">
                <template #header>
                  <el-image
                    :src="HOST + product.productPreviewImage"
                    alt="product"
                    style="width: 100%; height: 100%; aspect-ratio: 1"
                    :fit="fill"
                  >
                    <template #placeholder>
                      <div class="image-slot">
                        {{ appLocale.common.loading
                        }}<span class="dot">...</span>
                      </div>
                    </template>
                  </el-image>
                </template>
                <div class="product-name">{{ product.productName }}</div>
                <div class="product-description">
                  {{ product.productDescription }}
                </div>
                <template #footer>
                  <el-button
                    type="primary"
                    @click="viewProductDetails(product)"
                    style="bottom: 10px"
                    >{{ appLocale.product.viewDetail }}</el-button
                  >
                </template>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <el-footer>
      <footerCom />
    </el-footer>
  </el-container>
  <!-- Drawer for Filters on Small Screens -->
  <el-drawer
    title="Filters"
    v-model="drawerVisible"
    direction="ltr"
    size="300px"
  >
    <el-button @click="clearFilters" style="margin-bottom: 10px">
      Reset Filters
    </el-button>
    <el-switch
      v-model="singleSelect"
      active-text="Single Select"
      inactive-text="Multiple Select"
    />
    <el-tag
      v-for="filter in filters"
      :key="filter.id"
      :type="filter.active ? 'success' : 'info'"
      @click="toggleFilter(filter)"
      style="cursor: pointer; margin-bottom: 5px; min-width: 100px"
    >
      {{ filter.name }}
    </el-tag>
  </el-drawer>
</template>

<script>
import NavBar from "/src/components/NavBar.vue";
import { getProductsByCategory } from "@/api/product";
import footerCom from "@/components/footerCom.vue";
import { HOST } from "/src/utils/var.js";
import { localeState } from "@/locale.js";
export default {
  components: {
    NavBar,
    footerCom,
  },
  data() {
    return {
      drawerVisible: false,
      isSmallScreen: false,
      activeIndex: "2",
      filters: [],
      allProducts: [],
      filteredProducts: [],
      fetchLoading: false,
      HOST,
      singleSelect: false,
    };
  },
  beforeMount() {
    this.fetchProducts();
  },
  mounted() {
    // Check screen size on initial load
    this.checkScreenSize();

    // Add event listener for window resize
    window.addEventListener("resize", this.checkScreenSize);
  },
  beforeUnmount() {
    // Remove event listener on component destroy
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth < 1300;
    },
    handleMenuSelect(index) {
      this.activeIndex = index;
    },
    clearFilters() {
      this.filters.forEach((f) => (f.active = false));
      this.filteredProducts = this.allProducts;
    },
    toggleFilter(filter) {
      if (this.singleSelect) {
        this.filters.forEach((f) => (f.active = false));
        console.log("All filters are now inactive");
      }
      filter.active = !filter.active;
      this.filteredProducts = this.allProducts.filter((product) =>
        this.filters.some((f) => f.active && f.name === product.productType)
      );

      // If no filters are active, show all products
      if (!this.filters.some((f) => f.active)) {
        this.filteredProducts = this.allProducts;
      }
      console.log("Filtered products:", this.filteredProducts);
    },
    viewProductDetails(product) {
      this.$router.push({ name: "ProductDetail", params: { id: product.id } });
    },
    fetchProducts() {
      this.fetchLoading = true;
      console.log("Fetching product data...");
      getProductsByCategory("Teaware")
        .then((response) => {
          console.log("Product data:", response.data);
          this.allProducts = response.data.products;
          this.filteredProducts = this.allProducts;
          console.log("All products:", this.allProducts);
          this.filters = response.data.productTypes.map((type, index) => ({
            id: index + 1,
            name: type,
            active: false,
          }));
          this.fetchLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching product data:", error);
          this.fetchLoading = false;
        });
    },
  },
  computed: {
    appLocale() {
      return localeState.appLocale;
    },
    filterProducts() {
      // Check if any filter is active
      const activeFilters = this.filters
        .filter((f) => f.active)
        .map((f) => f.name);
      if (activeFilters.length === 0) {
        // No active filters, return all products
        return this.allProducts;
      }
      // Filter products based on active filters
      return this.allProducts.filter((product) =>
        activeFilters.includes(product.productType)
      );
    },
  },
};
</script>

<style scoped>
.custom-style .el-segmented {
  --el-segmented-item-selected-color: #ffffff;
  --el-segmented-item-selected-bg-color: #42fc4b;
  --el-border-radius-base: 16px;
}
.product-image {
  object-fit: cover;
  max-height: 100%;
}
.product-name {
  font-weight: bold;
}
.product-description {
  color: #888;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
}
.responsive-card-wrapper {
  width: 100%; /* Adjust based on the parent width */
  max-width: 300px; /* Max width of 300px */
  aspect-ratio: 3 / 5; /* Maintain 3:5 ratio */
}

.el-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.top-container {
  margin-top: 75px;
  height: 100%;
  width: auto;
}
.aside {
  height: 100%;
  width: 30%;
  border-right: 1px solid #f0f0f0;
  padding-top: 80px;
  background-color: #f0f0f0;
}
.top-cover {
  position: relative;
  width: 100%;
  height: 400px;
  background-image: url("https://via.placeholder.com/150");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}
.product-card {
  transition: all 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 20px;
}
</style>
