<template>
  <div>
    <h1>Dashboard</h1>
    <el-main
      style="display: flex; justify-content: center; align-items: center"
    >
      <el-image
        :src="mainUrl"
        alt="Welcome"
        style="width: 100%; height: 400px"
      />
    </el-main>
  </div>
</template>

<script>
import { HOST } from "../utils/var.js";
export default {
  data() {
    return {
      HOST,
      mainUrl: `${HOST}/static/home_images/main.jpg`,
    };
  },
};
</script>
