import { reactive } from "vue";
import { ElNotification } from "element-plus";
import { getUserRole } from "@/api/user";
// Create a reactive state object
const state = reactive({
  cartItems: [],
  user: null,
});

// Define mutations to modify the state
const mutations = {
  SET_USER(user) {
    state.user = user;
  },
  addToCart(product, quantity, selectedSpec) {
    // Find if the product with the same spec already exists in the cart
    const existingItem = state.cartItems.find(
      (item) =>
        item.product.id === product.id &&
        item.selectedSpec.id === selectedSpec.id
    );

    if (existingItem) {
      // If the same product with the same spec exists, update the quantity
      existingItem.quantity += quantity;
    } else {
      // Otherwise, add the product and its selected spec as a new item
      state.cartItems.push({ product, quantity, selectedSpec });
    }
  },
  updateQuantity(index, quantity) {
    state.cartItems[index].quantity = quantity;
  },
  removeFromCart(index) {
    state.cartItems.splice(index, 1);
  },

  clearCart() {
    state.cartItems = [];
  },
  loggedin(token, userName) {
    localStorage.setItem("token", token);
    localStorage.setItem("userName", userName);
  },
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    Loggout();
  },
};

const Loggout = () => {
  ElNotification({
    title: "Logged Out",
    message: "You have been logged out",
    type: "success",
    offset: 70,
  });
};
// Define actions
const actions = {
  async fetchUser() {
    try {
      const token = localStorage.getItem("token");
      if (token) {
        const role = await getUserRole(token);
        mutations.SET_USER(role ? role : null);
      }
    } catch (error) {
      console.error(error);
      mutations.SET_USER(null);
    }
  },
};
// Export the state and mutations
export default {
  state,
  mutations,
  actions,
};
