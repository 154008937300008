import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as Icons from "@element-plus/icons-vue";
import request from "./utils/request";

const app = createApp(App);

app.use(ElementPlus);
app.use(router);
app.config.globalProperties.$store = store;
app.mount("#app");

// Function to dynamically load and execute the config.js file
function loadConfigScript() {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = "/config.js";
    script.onload = () => resolve();
    script.onerror = () => reject(new Error("Failed to load config.js"));
    document.head.appendChild(script);
  });
}
// Register all icons globally
Object.keys(Icons).forEach((key) => {
  app.component(key, Icons[key]);
});

// Function to fetch the site name

function fetchSiteName() {
  return request.axiosGet("/pujing/getSiteName");
}

// Fetch the site name and set the title
fetchSiteName()
  .then((response) => {
    if (response.data && response.data.siteName) {
      document.title = response.data.siteName;
    } else {
      // Fetch config.js and set the title
      loadConfigScript()
        .then(() => {
          if (window.config && window.config.siteName) {
            document.title = window.config.siteName;
          } else {
            document.title = "Default Website Name";
          }
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
          document.title = "Default Website Name";
        });
    }
  })
  .catch((error) => {
    console.error("There has been a problem with your fetch operation:", error);
    document.title = "Default Website Name";
  });
