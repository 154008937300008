import request from "../utils/request";

export function getOrdersData(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axoisAuthGet("/pujing/getAllOrders", config);
}

export function updateOrderStatus(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthPost("/pujing/updateOrderStatus", jsondata, config);
}

export function getWebsiteConfig(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axoisAuthGet("/pujing/adminGetWebsiteConfig", config);
}

export function addProduct(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthPost("/pujing/adminAddProduct", jsondata, config);
}

export function updateProduct(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthPost("/pujing/adminUpdateProduct", jsondata, config);
}

export function deleteProduct(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthDelete(
    "/pujing/adminDeleteProduct",
    jsondata,
    config
  );
}

export function getCustomersInfo(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axoisAuthGet("/pujing/adminGetCustomersInfo", config);
}

export function uploadImage(formData, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return request.axiosAuthPost(
    "/pujing/adminUploadProductImage",
    formData,
    config
  );
}
export function uploadCover(formData, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return request.axiosAuthPost(
    "/pujing/adminUploadProductCoverPhoto",
    formData,
    config
  );
}

export function getProductImages(id, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axoisAuthGet(`/pujing/adminGetProductImages/${id}`, config);
}

export function deleteProductImage(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthDelete(
    "/pujing/adminDeleteProductImage",
    jsondata,
    config
  );
}

export function updateWebsiteConfig(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthPost(
    "/pujing/adminUpdateWebsiteConfig",
    jsondata,
    config
  );
}

export function updateHomeData(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthPost("/pujing/adminUpdateHomeData", jsondata, config);
}

export function uploadHomePageImage(formData, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return request.axiosAuthPost(
    "/pujing/adminUploadHomePageImage",
    formData,
    config
  );
}
export function uploadCarouselImage(formData, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return request.axiosAuthPost(
    "/pujing/adminUploadCarouselImage",
    formData,
    config
  );
}

export function deleteCarouselImage(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthDelete(
    "/pujing/adminDeleteCarouselImage",
    jsondata,
    config
  );
}

export function getAllProducts(token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axoisAuthGet("/pujing/adminGetTeaProducts", config);
}

export function updateFeaturedProducts(jsondata, token) {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request.axiosAuthPost(
    "/pujing/adminUpdateFeaturedProducts",
    jsondata,
    config
  );
}
