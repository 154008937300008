import axios from "axios";
//基础地址
axios.defaults.baseURL = "http://ooaago.com";

// http://127.0.0.1:5000
// http://ooaago.com

//响应拦截
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      if (error.response.data.msg === "token expired") {
        console.log("token expired");
      }
    }
    return Promise.reject(error);
  }
);

export default {
  //get请求
  axiosGet(url, params) {
    return axios({
      url,
      params,
    });
  },
  axoisAuthGet(url, config) {
    return axios({
      url,
      ...config,
    });
  },
  //其他方式请求
  axiosRequest(url, data, method = "POST") {
    return axios({
      url,
      method,
      data,
    });
  },
  axiosAuthPost(url, data, config) {
    return axios({
      url,
      method: "POST",
      data,
      ...config,
    });
  },
  axiosAuthDelete(url, data, config) {
    return axios({
      url,
      method: "DELETE",
      data,
      ...config,
    });
  },
};
