<template>
  <el-container
    style="min-height: 100vh; display: flex; flex-direction: column"
  >
    <el-header class="sticky-div">
      <NavBar :activeIndex="activeIndex" @menu-select="handleMenuSelect" />
    </el-header>
    <el-main style="display: flex; padding-top: 80px">
      <el-container
        v-loading="loading"
        style="
          justify-content: center;
          align-items: center;
          flex: 1;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 100vh;
        "
        :element-loading-text="loadingText"
      >
        <el-card shadow="always">
          <template #header>
            <div>
              <span>{{ appLocale.account.details }}</span>
            </div>
          </template>
          <el-tabs
            tab-position="left"
            style="height: 480px; width: 1000px; overflow: auto"
          >
            <div style="overflow: auto; height: 100%; box-sizing: border-box">
              <el-tab-pane :label="appLocale.account.details">
                <div v-if="user">
                  <el-form :model="user" label-width="120px">
                    <el-form-item :label="appLocale.account.username">
                      <el-input v-model="user.username"></el-input>
                    </el-form-item>
                    <el-form-item :label="appLocale.account.userId">
                      <el-input v-model="user.id" disabled></el-input>
                    </el-form-item>
                    <el-form-item :label="appLocale.account.customer">
                      <el-input v-model="user.userType" disabled></el-input>
                    </el-form-item>
                    <el-form-item :label="appLocale.account.email">
                      <el-input v-model="user.email"></el-input>
                    </el-form-item>
                    <el-form-item :label="appLocale.account.phone">
                      <el-input v-model="user.phone"></el-input>
                    </el-form-item>
                    <el-button type="primary" @click="saveUserDetails">
                      {{ appLocale.account.save }}</el-button
                    >
                  </el-form>
                </div>
              </el-tab-pane>

              <el-tab-pane :label="appLocale.addresses.title">
                <div v-if="user">
                  <el-table :data="user.addresses" style="width: 100%">
                    <el-table-column
                      prop="id"
                      label="ID"
                      width="80"
                    ></el-table-column>
                    <el-table-column
                      prop="firstName"
                      :label="appLocale.addresses.firstName"
                      width="100"
                    ></el-table-column>
                    <el-table-column
                      prop="lastName"
                      :label="appLocale.addresses.lastName"
                      width="120"
                    ></el-table-column>
                    <el-table-column
                      :label="appLocale.addresses.actions"
                      fixed="right"
                      min-width="240px"
                    >
                      <template v-slot="scope">
                        <el-button
                          link
                          size="small"
                          type="primary"
                          @click="editAddress(scope.row)"
                          >{{ appLocale.addresses.edit }}</el-button
                        >
                        <el-button
                          link
                          size="small"
                          type="danger"
                          @click="removeAddress(scope.row)"
                          >{{ appLocale.addresses.delete }}</el-button
                        >
                        <el-button
                          link
                          size="small"
                          type="warning"
                          @click="duplicateAddress(scope.row)"
                          >{{ appLocale.addresses.duplicate }}</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-button
                    type="primary"
                    @click="addAddress"
                    style="margin-top: 10px"
                    >{{ appLocale.addresses.addNew }}</el-button
                  >
                </div>

                <!-- Address Edit Dialog -->
                <el-dialog
                  v-model="editDialogVisible"
                  :title="appLocale.addresses.edit"
                  @close="editDialogVisible = false"
                >
                  <el-form :model="addressForm">
                    <el-row>
                      <el-input v-model="addressForm.id"></el-input>
                    </el-row>
                    <el-row>
                      <el-form-item prop="firstName">
                        <el-input
                          v-model="addressForm.firstName"
                          :placeholder="appLocale.addresses.firstName"
                        ></el-input>
                      </el-form-item>
                      <el-form-item prop="lastName">
                        <el-input
                          v-model="addressForm.lastName"
                          :placeholder="appLocale.addresses.lastName"
                        ></el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item prop="email">
                        <el-input
                          v-model="addressForm.email"
                          :placeholder="appLocale.addresses.email"
                        ></el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item prop="phone">
                        <el-input
                          v-model="addressForm.phone"
                          :placeholder="appLocale.addresses.phone"
                        ></el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item>
                        <el-select
                          disabled
                          placeholder="Australia"
                          style="width: 240px"
                        >
                          <el-option> </el-option>
                        </el-select>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item prop="street">
                        <el-input
                          v-model="addressForm.street"
                          :placeholder="appLocale.addresses.street"
                        >
                        </el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item prop="city">
                        <el-input
                          v-model="addressForm.city"
                          :placeholder="appLocale.addresses.city"
                        >
                        </el-input>
                      </el-form-item>
                    </el-row>

                    <el-row>
                      <el-form-item prop="state">
                        <el-select
                          v-model="addressForm.state"
                          :placeholder="appLocale.addresses.state"
                          style="width: 200px"
                        >
                          <el-option
                            label="New South Wales"
                            value="NSW"
                          ></el-option>
                          <el-option label="Victoria" value="VIC"></el-option>
                          <el-option label="Queensland" value="QLD"></el-option>
                          <el-option
                            label="Western Australia"
                            value="WA"
                          ></el-option>
                          <el-option
                            label="South Australia"
                            value="SA"
                          ></el-option>
                          <el-option label="Tasmania" value="TAS"></el-option>
                          <el-option
                            label="Northern Territory"
                            value="NT"
                          ></el-option>
                          <el-option
                            label="Australian Capital Territory"
                            value="ACT"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-form-item prop="zip">
                        <el-input
                          v-model="addressForm.zip"
                          :placeholder="appLocale.addresses.postcode"
                        ></el-input>
                      </el-form-item>
                    </el-row>
                    <el-row>
                      <el-checkbox
                        prop="isDefaultAddress"
                        v-model="addressForm.isDefault"
                      >
                        <span>{{ appLocale.addresses.default }}</span>
                      </el-checkbox>
                    </el-row>
                  </el-form>
                  <div class="dialog-footer">
                    <el-button @click="editDialogVisible = false">{{
                      appLocale.addresses.cancel
                    }}</el-button>
                    <el-button type="primary" @click="onClickSaveAddress">{{
                      appLocale.addresses.save
                    }}</el-button>
                  </div>
                </el-dialog>
              </el-tab-pane>
              <el-tab-pane :label="appLocale.orders.title">
                <el-table
                  v-if="user.orders && user.orders.length > 0"
                  :data="user.orders"
                  style="width: 100%"
                >
                  <el-table-column
                    fixed="left"
                    prop="id"
                    :label="appLocale.orders.orderId"
                    width="100"
                  ></el-table-column>
                  <el-table-column
                    prop="orderTotalPrice"
                    :label="appLocale.orders.total"
                    width="150"
                  ></el-table-column>
                  <el-table-column
                    prop="orderStatus"
                    :label="appLocale.orders.status"
                    width="150"
                  ></el-table-column>
                  <el-table-column
                    fixed="right"
                    :label="appLocale.orders.operations"
                    min-width="150"
                  >
                    <template v-slot="scope">
                      <el-button
                        type="primary"
                        size="small"
                        @click="viewOrderDetails(scope.row)"
                        >{{ appLocale.orders.viewDetails }}</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
                <p v-else>{{ appLocale.orders.details.noOrders }}</p>
                <!-- Order Details Dialog -->
                <el-dialog
                  v-model="orderDetailsVisible"
                  :title="appLocale.orders.details.title"
                  @close="orderDetailsVisible = false"
                  style="width: 800px"
                >
                  <el-divider>{{ appLocale.orders.details.title }}</el-divider>

                  <el-descriptions
                    :title="appLocale.orders.details.title"
                    :column="4"
                    border
                  >
                    <el-descriptions-item :label="appLocale.orders.orderId">
                      {{ selectedOrder.id }}
                    </el-descriptions-item>
                    <el-descriptions-item
                      :label="appLocale.orders.details.placedTime"
                    >
                      {{ selectedOrder.orderPlacedTime }}
                    </el-descriptions-item>
                    <el-descriptions-item
                      :label="appLocale.orders.details.totalPrice"
                    >
                      {{ selectedOrder.orderTotalPrice }}
                    </el-descriptions-item>
                    <el-descriptions-item
                      :label="appLocale.orders.details.paymentAmount"
                    >
                      {{ selectedOrder.orderPaymentAmount }}
                    </el-descriptions-item>
                  </el-descriptions>

                  <el-divider>{{ appLocale.orders.allItems }}</el-divider>

                  <el-table
                    :data="selectedOrder.orderItemInfo"
                    style="width: 100%"
                    stripe
                    border
                  >
                    <el-table-column
                      prop="productName"
                      :label="appLocale.cart.productName"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="quantity"
                      :label="appLocale.cart.quantity"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="selectedSpec.price"
                      :label="appLocale.cart.price"
                    ></el-table-column>
                    <el-table-column
                      prop="selectedSpec.bestPrice"
                      :label="appLocale.cart.bestPrice"
                    ></el-table-column>
                  </el-table>

                  <el-divider>{{ appLocale.addresses.billing }}</el-divider>

                  <el-descriptions
                    :title="appLocale.addresses.billing"
                    :column="4"
                    border
                  >
                    <el-descriptions-item
                      :label="appLocale.addresses.firstName"
                    >
                      {{ selectedOrder.orderBillingFirstName }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.lastName">
                      {{ selectedOrder.orderBillingLastName }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.phone">
                      {{ selectedOrder.orderBillingPhone }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.email">
                      {{ selectedOrder.orderBillingEmail }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.street">
                      {{ selectedOrder.orderBillingAddressStreet }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.city">
                      {{ selectedOrder.orderBillingAddressCity }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.state">
                      {{ selectedOrder.orderBillingAddressState }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.postcode">
                      {{ selectedOrder.orderBillingAddressZip }}
                    </el-descriptions-item>
                  </el-descriptions>

                  <el-divider>{{ appLocale.orders.shipping }}</el-divider>

                  <el-descriptions
                    :title="appLocale.orders.shipping"
                    :column="4"
                    border
                  >
                    <el-descriptions-item
                      :label="appLocale.addresses.firstName"
                    >
                      {{ selectedOrder.orderShippingFirstName }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.lastName">
                      {{ selectedOrder.orderShippingLastName }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.phone">
                      {{ selectedOrder.orderShippingPhone }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.email">
                      {{ selectedOrder.orderShippingEmail }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.street">
                      {{ selectedOrder.orderShippingAddressStreet }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.city">
                      {{ selectedOrder.orderShippingAddressCity }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.state">
                      {{ selectedOrder.orderShippingAddressState }}
                    </el-descriptions-item>
                    <el-descriptions-item :label="appLocale.addresses.postcode">
                      {{ selectedOrder.orderShippingAddressZip }}
                    </el-descriptions-item>
                  </el-descriptions>

                  <div class="dialog-footer" style="margin-top: 10px">
                    <el-button>{{
                      appLocale.orders.actions.addToCart
                    }}</el-button>
                    <el-button>{{
                      appLocale.orders.actions.trackOrder
                    }}</el-button>
                    <el-button>{{
                      appLocale.orders.actions.emailReceipt
                    }}</el-button>
                    <el-button>{{
                      appLocale.orders.actions.question
                    }}</el-button>
                  </div>
                </el-dialog>
              </el-tab-pane>
            </div>
          </el-tabs>
        </el-card>
      </el-container>
    </el-main>
    <el-footer>
      <footerCom />
    </el-footer>
  </el-container>
</template>

<script>
import NavBar from "/src/components/NavBar.vue";
import footerCom from "@/components/footerCom.vue";
import { ElMessage } from "element-plus";
import { localeState } from "@/locale.js";
import {
  getUserData,
  getUserOrders,
  addUserAddress,
  getUserAddresses,
  updateUserAddress,
  deleteUserAddress,
  getAccountData,
  updateAccountData,
} from "@/api/user";

export default {
  components: {
    NavBar,
    footerCom,
  },
  data() {
    return {
      text: "Welcome to the Account page!",
      loadingText: "",
      user: {
        username: "",
        id: "",
        userType: "",
        addresses: [],
        email: "",
        phone: "",
        orders: [],
      },
      addressForm: {
        id: 1,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        isDefault: false,
      },
      editDialogVisible: false,
      isAddingAddress: false,
      orderDetailsVisible: false,
      selectedOrder: null,
      loading: false,
    };
  },
  created() {
    const token = localStorage.getItem("token");
    if (!token) {
      this.$router.push("/login");
      return;
    }
    this.fetchAccountData(token);
  },
  computed: {
    appLocale() {
      return localeState.appLocale;
    },
  },
  methods: {
    fetchAccountData(token) {
      this.loadingText = "Fetching Account data...";
      this.loading = true;
      getAccountData(token)
        .then((response) => {
          console.log("Account data:", response.data);
          this.user.username = response.data.user.username;
          this.user.id = response.data.user.id;
          this.user.userType = response.data.user.userType;
          this.user.email = response.data.user.userEmail;
          this.user.phone = response.data.user.userPhone;
          this.user.addresses = response.data.user.userAddresses;
          this.user.orders = response.data.orders;
        })
        .catch((error) => {
          console.error("Error fetching account data:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchUserData(token) {
      this.loadingText = "Fetching Account data...";
      this.loading = true;
      getUserData(token)
        .then((response) => {
          this.user.username = response.data.username;
          this.user.id = response.data.id;
          this.user.userType = response.data.userType;
          this.user.email = response.data.email;
          this.user.phone = response.data.phone;
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        })
        .finally(() => {
          this.loading = false;
          this.fetchUserAddresses(token);
        });
    },
    fetchUserAddresses(token) {
      this.loadingText = "Fetching user addresses...";
      this.loading = true;
      getUserAddresses(token)
        .then((response) => {
          console.log("User addresses:", response.data);
          this.user.addresses = response.data;
        })
        .catch((error) => {
          console.error("Error fetching user addresses:", error);
        })
        .finally(() => {
          this.loading = false;
          this.fetchUserOrders(token);
        });
    },
    fetchUserOrders(token) {
      this.loadingText = "Fetching user orders...";
      this.loading = true;
      getUserOrders(token)
        .then((response) => {
          console.log("User orders:", response.data);
          this.user.orders = response.data;
        })
        .catch((error) => {
          console.error("Error fetching user orders:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    saveUserDetails() {
      console.log("User details updating", this.user);
      const token = localStorage.getItem("token");
      const data = {
        username: this.user.username,
        email: this.user.email,
        phone: this.user.phone,
      };
      updateAccountData(data, token)
        .then((response) => {
          console.log("Account data updated:", response.data);
          ElMessage({
            message: "Account details updated",
            type: "success",
          });
        })
        .catch((error) => {
          console.error("Error updating account data:", error);
          ElMessage({
            message: "Error updating account details",
            type: "error",
          });
        });
    },
    editAddress(address) {
      this.addressForm = { ...address };
      this.editDialogVisible = true;
      this.isAddingAddress = false;
    },
    addAddress() {
      this.isAddingAddress = true;
      this.editDialogVisible = true;
      // reset address form
      this.addressForm = {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        isDefault: false,
      };
    },
    onClickSaveAddress() {
      if (this.isAddingAddress) {
        this.loadingText = "Adding New Address...";
        this.loading = true;

        const token = localStorage.getItem("token");
        const data = {
          address: {
            id: this.addressForm.id,
            firstName: this.addressForm.firstName,
            lastName: this.addressForm.lastName,
            email: this.addressForm.email,
            phone: this.addressForm.phone,
            street: this.addressForm.street,
            city: this.addressForm.city,
            state: this.addressForm.state,
            zip: this.addressForm.zip,
            isDefault: this.addressForm.isDefault,
          },
        };
        addUserAddress(data, token)
          .then((response) => {
            ElMessage({
              message: "Address added",
              type: "success",
            });
            console.log("Address added:", response.data.address);
            this.loading = false;
            this.user.addresses = response.data.address;
          })
          .catch((error) => {
            ElMessage({
              message: "Error adding address",
              type: "error",
            });
            console.error("Error adding address:", error);
            this.loading = false;
          });
      } else {
        this.loadingText = "Updating Existing Address...";
        this.loading = true;

        const token = localStorage.getItem("token");
        const data = {
          address: {
            id: this.addressForm.id,
            firstName: this.addressForm.firstName,
            lastName: this.addressForm.lastName,
            email: this.addressForm.email,
            phone: this.addressForm.phone,
            street: this.addressForm.street,
            city: this.addressForm.city,
            state: this.addressForm.state,
            zip: this.addressForm.zip,
            isDefault: this.addressForm.isDefault,
          },
        };
        updateUserAddress(data, token)
          .then((response) => {
            ElMessage({
              message: "Address updated",
              type: "success",
            });
            console.log("Address updated:", response.data);
            this.loading = false;
            this.user.addresses = response.data.address;
          })
          .catch((error) => {
            ElMessage({
              message: "Error updating address",
              type: "error",
            });
            console.error("Error updating address:", error);
            this.loading = false;
          });
      }
      this.editDialogVisible = false;
    },

    removeAddress(address) {
      ElMessage({
        message: "Removing address...",
      });
      const token = localStorage.getItem("token");
      const data = {
        id: { ...address }.id,
      };
      deleteUserAddress(data, token)
        .then((response) => {
          console.log("Address deleted:", response.data);
          ElMessage({
            message: "Address deleted",
            type: "success",
          });
          this.user.addresses = response.data.address;
        })
        .catch((error) => {
          console.error("Error deleting address:", error);
          ElMessage({
            message: "Error deleting address",
            type: "error",
          });
        });
    },
    duplicateAddress(address) {
      const temp = { ...address };
      temp.id = null;
      temp.isDefault = false;
      ElMessage({
        message: "Duplicating address...",
      });
      const token = localStorage.getItem("token");
      const data = {
        address: temp,
      };
      addUserAddress(data, token)
        .then((response) => {
          console.log("Address duplicated:", response.data);
          ElMessage({
            message: "Address duplicated",
            type: "success",
          });
          this.user.addresses = response.data.address;
        })
        .catch((error) => {
          console.error("Error duplicating address:", error);
          ElMessage({
            message: "Error duplicating address",
            type: "error",
          });
        });
    },
    viewOrderDetails(order) {
      this.selectedOrder = order;
      this.orderDetailsVisible = true;
    },
    formatLabel(camelCaseString) {
      return camelCaseString
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space between lowercase and uppercase letters
        .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Insert space between consecutive uppercase letters and following lowercase
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter of the resulting string
    },
  },
};
</script>

<style scoped>
.el-tabs__content {
  overflow: auto;
  position: relative;
}
</style>
