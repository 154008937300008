<template>
  <div class="admin-page">
    <el-container style="min-height: 100vh">
      <el-header>
        <h1>
          Admin Panel
          <el-link href="/">Home</el-link>
        </h1>
      </el-header>
      <el-container>
        <el-aside width="15%">
          <el-menu
            :default-active="defaultActive"
            class="el-menu-vertical-demo"
            @select="handleMenuSelect"
          >
            <el-menu-item index="/admin">Dashboard</el-menu-item>
            <el-menu-item index="/admin/website-config"
              >Website Config</el-menu-item
            >
            <el-menu-item index="/admin/product-management"
              >Product Management</el-menu-item
            >
            <el-menu-item index="/admin/order-management"
              >Order Management</el-menu-item
            >
            <el-menu-item index="/admin/customer-management"
              >Customer Management</el-menu-item
            >
            <el-menu-item index="/admin/csm"
              >Customer Service Management</el-menu-item
            >
          </el-menu>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "AdminPage",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const defaultActive = ref(route.path);

    function handleMenuSelect(index) {
      router.push(index);
    }

    return {
      defaultActive,
      handleMenuSelect,
    };
  },
});
</script>

<style scoped>
.admin-page {
  height: 100%;
}
.el-container {
  height: 100%;
}
.el-menu {
  height: 100%;
}
.el-main {
  padding: 20px;
}
</style>
