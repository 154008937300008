<template>
  <el-container>
    <el-header class="sticky-div">
      <NavBar :activeIndex="activeIndex" @menu-select="handleMenuSelect" />
    </el-header>
    <el-main style="padding-top: 80px">
      <el-row justify="center" style="height: 100vh">
        <el-col :span="16">
          <!-- left area -->
          <!-- Not Logged In area -->
          <div class="customer-details" v-if="!isLoggedIn">
            <el-row style="justify-content: center; padding-top: 10px">
              <el-text>
                <el-icon><User /></el-icon>
                {{ appLocale.checkout.alreadyHaveAccount }}
              </el-text>
              <el-button> {{ appLocale.checkout.login }} </el-button>
            </el-row>
          </div>
          <!-- Logged In  -->
          <div v-else>
            <el-card
              style="max-width: 50%; margin: 20px auto"
              v-loading="loading"
            >
              <template #header>
                <el-row>
                  <el-col :span="4">
                    <el-text>{{ appLocale.checkout.loggedIn }}</el-text>
                  </el-col>
                  <el-col :span="4">
                    <el-text>{{ appLocale.checkout.selectAddress }}</el-text>
                  </el-col>
                  <el-col :span="12">
                    <el-select
                      v-model="selectedAddress"
                      value-key="id"
                      :placeholder="appLocale.checkout.selectAnAddress"
                      @change="handleAddressChange"
                    >
                      <el-option
                        v-for="address in addresses"
                        :key="address.id"
                        :label="formatAddress(address)"
                        :value="address"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="4">
                    <el-button
                      type="info"
                      round
                      @click="fetchUserAddresses"
                      :disabled="isFetching"
                      ><el-icon><Refresh /></el-icon
                    ></el-button>
                  </el-col>
                </el-row>
              </template>

              <el-descriptions
                :title="appLocale.checkout.defaultShippingInfo"
                border
                :column="1"
                :size="small"
              >
                <el-descriptions-item :label="appLocale.checkout.firstName">
                  {{ selectedAddress.firstName }}
                </el-descriptions-item>
                <el-descriptions-item :label="appLocale.checkout.lastName">
                  {{ selectedAddress.lastName }}
                </el-descriptions-item>
                <el-descriptions-item :label="appLocale.checkout.email">
                  {{ selectedAddress.email }}
                </el-descriptions-item>
                <el-descriptions-item :label="appLocale.checkout.phone">
                  {{ selectedAddress.phone }}
                </el-descriptions-item>

                <el-descriptions-item
                  :label="appLocale.checkout.shippingAddress"
                >
                  {{ selectedAddress.street }}, {{ selectedAddress.city }},
                  {{ selectedAddress.state }} {{ selectedAddress.zip }}
                </el-descriptions-item>
              </el-descriptions>
              <div style="padding-top: 20px">
                <el-button type="primary" round @click="applyAddress">
                  {{ appLocale.checkout.applyThisAddress }}
                </el-button>
              </div>
            </el-card>
          </div>
          <el-row>
            <el-divider>{{ appLocale.checkout.billingDetails }}</el-divider>
          </el-row>
          <!-- New Input Form -->
          <el-form
            class="form"
            :rules="checkoutFormRules"
            :model="checkoutForm"
            ref="checkoutForm"
          >
            <el-row>
              <el-form-item prop="billingFirstName">
                <el-input
                  v-model="checkoutForm.billingFirstName"
                  :placeholder="appLocale.checkout.firstName"
                ></el-input>
              </el-form-item>
              <el-form-item prop="billingLastName">
                <el-input
                  v-model="checkoutForm.billingLastName"
                  :placeholder="appLocale.checkout.lastName"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="billingEmail">
                <el-input
                  v-model="checkoutForm.billingEmail"
                  :placeholder="appLocale.checkout.email"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="billingPhone">
                <el-input
                  v-model="checkoutForm.billingPhone"
                  :placeholder="appLocale.checkout.phone"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item>
                <el-select
                  disabled
                  placeholder="Australia"
                  style="width: 240px"
                >
                  <el-option> </el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="billingAddress_street">
                <el-input
                  v-model="checkoutForm.billingAddress_street"
                  :placeholder="appLocale.checkout.streetAddress"
                >
                </el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="billingAddress_city">
                <el-input
                  v-model="checkoutForm.billingAddress_city"
                  :placeholder="appLocale.checkout.city"
                >
                </el-input>
              </el-form-item>
            </el-row>

            <el-row>
              <el-form-item prop="billingAddress_state">
                <el-select
                  v-model="checkoutForm.billingAddress_state"
                  :placeholder="appLocale.checkout.state"
                  style="width: 200px"
                >
                  <el-option label="New South Wales" value="NSW"></el-option>
                  <el-option label="Victoria" value="VIC"></el-option>
                  <el-option label="Queensland" value="QLD"></el-option>
                  <el-option label="Western Australia" value="WA"></el-option>
                  <el-option label="South Australia" value="SA"></el-option>
                  <el-option label="Tasmania" value="TAS"></el-option>
                  <el-option label="Northern Territory" value="NT"></el-option>
                  <el-option
                    label="Australian Capital Territory"
                    value="ACT"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item prop="billingAddress_zip">
                <el-input
                  v-model="checkoutForm.billingAddress_zip"
                  :placeholder="appLocale.checkout.postcode"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-checkbox v-model="checkoutForm.isSameAsBilling">{{
                appLocale.checkout.shippingToBillingAddress
              }}</el-checkbox>
            </el-row>
            <!-- Got Different Shipping Address -->
            <div v-if="!checkoutForm.isSameAsBilling">
              <el-divider>{{ appLocale.checkout.shippingDetails }}</el-divider>
              <el-row>
                <el-form-item prop="shippingAddress_street">
                  <el-input
                    v-model="checkoutForm.shippingAddress_street"
                    :placeholder="appLocale.checkout.streetAddress"
                  >
                  </el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item prop="shippingAddress_city">
                  <el-input
                    v-model="checkoutForm.shippingAddress_city"
                    :placeholder="appLocale.checkout.city"
                  >
                  </el-input>
                </el-form-item>
              </el-row>

              <el-row>
                <el-form-item prop="shippingAddress_state">
                  <el-select
                    v-model="checkoutForm.shippingAddress_state"
                    :placeholder="appLocale.checkout.state"
                    style="width: 200px"
                  >
                    <el-option label="New South Wales" value="NSW"></el-option>
                    <el-option label="Victoria" value="VIC"></el-option>
                    <el-option label="Queensland" value="QLD"></el-option>
                    <el-option label="Western Australia" value="WA"></el-option>
                    <el-option label="South Australia" value="SA"></el-option>
                    <el-option label="Tasmania" value="TAS"></el-option>
                    <el-option
                      label="Northern Territory"
                      value="NT"
                    ></el-option>
                    <el-option
                      label="Australian Capital Territory"
                      value="ACT"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item prop="shippingAddress_zip">
                  <el-input
                    v-model="checkoutForm.shippingAddress_zip"
                    :placeholder="appLocale.checkout.postcode"
                  ></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item prop="shippingFirstName">
                  <el-input
                    v-model="checkoutForm.shippingFirstName"
                    :placeholder="appLocale.checkout.firstName"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="shippingLastName">
                  <el-input
                    v-model="checkoutForm.shippingLastName"
                    :placeholder="appLocale.checkout.lastName"
                  ></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item prop="shippingEmail">
                  <el-input
                    v-model="checkoutForm.shippingEmail"
                    :placeholder="appLocale.checkout.email"
                  ></el-input>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item prop="shippingPhone">
                  <el-input
                    v-model="checkoutForm.shippingPhone"
                    :placeholder="appLocale.checkout.phone"
                  ></el-input>
                </el-form-item>
              </el-row>
            </div>
          </el-form>
        </el-col>
        <!-- right area -->
        <el-col :span="8" style="background-color: lightgrey">
          <!-- Order Summary -->
          <h2>{{ appLocale.checkout.orderSummary }}</h2>
          <CartCom />
          <el-button @click="checkOutTest" type="primary">{{
            appLocale.checkout.checkoutButton
          }}</el-button>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
import NavBar from "/src/components/NavBar.vue";
import { placeOrder, placeOrderWithToken } from "@/api/checkout";
import { getUserAddresses } from "@/api/user";
import { ElMessage } from "element-plus";
import CartCom from "@/components/CartCom.vue";
import { localeState } from "@/locale.js";
export default {
  components: {
    NavBar,
    CartCom,
  },
  data() {
    return {
      activeIndex: null,
      isLoggedIn: !!localStorage.getItem("token"),
      checkoutForm: {
        billingFirstName: "",
        billingLastName: "",
        billingEmail: "",
        billingPhone: "",
        billingAddress_street: "",
        billingAddress_city: "",
        billingAddress_state: "",
        billingAddress_zip: "",
        isSameAsBilling: true,
        shippingFirstName: "",
        shippingLastName: "",
        shippingEmail: "",
        shippingPhone: "",
        shippingAddress_street: "",
        shippingAddress_city: "",
        shippingAddress_state: "",
        shippingAddress_zip: "",
        selectedPaymentMethod: "credit",
      },
      checkoutFormRules: {
        billingFirstName: [
          {
            required: true,
            message: "Please input billing first name",
            trigger: "blur",
          },
        ],
        billingLastName: [
          {
            required: true,
            message: "Please input billing last name",
            trigger: "blur",
          },
        ],
        billingEmail: [
          {
            required: true,
            message: "Please input billing email",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please input correct email address",
            trigger: ["blur", "change"],
          },
        ],
        billingPhone: [
          {
            required: true,
            message: "Please input billing phone",
            trigger: "blur",
          },
        ],
        billingAddress_street: [
          {
            required: true,
            message: "Please input billing address",
            trigger: "blur",
          },
        ],
        billingAddress_city: [
          {
            required: true,
            message: "Please input billing city",
            trigger: "blur",
          },
        ],
        billingAddress_state: [
          {
            required: true,
            message: "Please input billing state",
            trigger: "blur",
          },
        ],
        billingAddress_zip: [
          {
            required: true,
            message: "Please input billing zip",
            trigger: "blur",
          },
        ],
        shippingFirstName: [
          {
            required: true,
            message: "Please input shipping first name",
            trigger: "blur",
          },
        ],
        shippingLastName: [
          {
            required: true,
            message: "Please input shipping last name",
            trigger: "blur",
          },
        ],
        shippingEmail: [
          {
            required: true,
            message: "Please input shipping email",
            trigger: "blur",
          },
        ],
        shippingPhone: [
          {
            required: true,
            message: "Please input shipping phone",
            trigger: "blur",
          },
        ],
        shippingAddress_street: [
          {
            required: true,
            message: "Please input shipping address",
            trigger: "blur",
          },
        ],
        shippingAddress_city: [
          {
            required: true,
            message: "Please input shipping city",
            trigger: "blur",
          },
        ],
        shippingAddress_state: [
          {
            required: true,
            message: "Please input shipping state",
            trigger: "blur",
          },
        ],
        shippingAddress_zip: {
          required: true,
          message: "Please input shipping zip",
          trigger: "blur",
        },
      },
      addresses: [],
      selectedAddress: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        street: "",
        city: "",
        state: "",
        zip: "",
      },
      userData: {},
      loading: true,
      isFetching: false,
    };
  },
  computed: {
    cartItems() {
      return this.$store.state.cartItems;
    },
    appLocale() {
      return localeState.appLocale;
    },
  },
  mounted() {
    this.isLoggedIn = !!localStorage.getItem("token");
  },
  methods: {
    handleMenuSelect(index) {
      this.activeIndex = index;
    },
    removeFromCart(index) {
      this.$store.mutations.removeFromCart(index);
    },
    goShopping() {
      this.$router.push("/products");
    },
    updateQuantity(index, quantity) {
      this.$store.mutations.updateQuantity(index, quantity);
    },
    applyAddress() {
      this.checkoutForm.billingFirstName = this.selectedAddress.firstName;
      this.checkoutForm.billingLastName = this.selectedAddress.lastName;
      this.checkoutForm.billingEmail = this.selectedAddress.email;
      this.checkoutForm.billingPhone = this.selectedAddress.phone;
      this.checkoutForm.billingAddress_street = this.selectedAddress.street;
      this.checkoutForm.billingAddress_city = this.selectedAddress.city;
      this.checkoutForm.billingAddress_state = this.selectedAddress.state;
      this.checkoutForm.billingAddress_zip = this.selectedAddress.zip;
    },
    checkOutTest() {
      this.$refs.checkoutForm.validate((valid) => {
        if (valid) {
          ElMessage({
            message: "Placing your order...",
            type: "success",
          });
          this.placeOrderConfirmed();
        } else {
          ElMessage.error("Please fill in all required fields");
          return;
        }
      });
    },
    placeOrderConfirmed() {
      const token = localStorage.getItem("token");
      const orderData = {
        orderBillingFirstName: this.checkoutForm.billingFirstName,
        orderBillingLastName: this.checkoutForm.billingLastName,
        orderBillingEmail: this.checkoutForm.billingEmail,
        orderBillingPhone: this.checkoutForm.billingPhone,
        orderBillingAddressStreet: this.checkoutForm.billingAddress_street,
        orderBillingAddressCity: this.checkoutForm.billingAddress_city,
        orderBillingAddressState: this.checkoutForm.billingAddress_state,
        orderBillingAddressZip: this.checkoutForm.billingAddress_zip,
        // Use billing address for shipping if isSameBilling is true, otherwise use shipping fields
        orderShippingFirstName: this.checkoutForm.isSameAsBilling
          ? this.checkoutForm.billingFirstName
          : this.checkoutForm.shippingFirstName,
        orderShippingLastName: this.checkoutForm.isSameAsBilling
          ? this.checkoutForm.billingLastName
          : this.checkoutForm.shippingLastName,
        orderShippingEmail: this.checkoutForm.isSameAsBilling
          ? this.checkoutForm.billingEmail
          : this.checkoutForm.shippingEmail,
        orderShippingPhone: this.checkoutForm.isSameAsBilling
          ? this.checkoutForm.billingPhone
          : this.checkoutForm.shippingPhone,
        orderShippingAddressStreet: this.checkoutForm.isSameAsBilling
          ? this.checkoutForm.billingAddress_street
          : this.checkoutForm.shippingAddress_street,
        orderShippingAddressCity: this.checkoutForm.isSameAsBilling
          ? this.checkoutForm.billingAddress_city
          : this.checkoutForm.shippingAddress_city,
        orderShippingAddressState: this.checkoutForm.isSameAsBilling
          ? this.checkoutForm.billingAddress_state
          : this.checkoutForm.shippingAddress_state,
        orderShippingAddressZip: this.checkoutForm.isSameAsBilling
          ? this.checkoutForm.billingAddress_zip
          : this.checkoutForm.shippingAddress_zip,
        orderPaymentMethod: this.checkoutForm.selectedPaymentMethod,
        orderItemInfo: this.cartItems,
      };
      if (token) {
        placeOrderWithToken(orderData, token)
          .then((response) => {
            console.log(response);
            ElMessage({
              message: "Order Placed",
              type: "success",
            });
            // store the order Details to session storage
            sessionStorage.setItem("orderData", JSON.stringify(response.data));
            this.$router.push("/order");
          })
          .catch((error) => {
            ElMessage({
              message: "Error placing order",
              type: "error",
            });
            console.log(error);
          });
      } else {
        placeOrder(orderData)
          .then((response) => {
            console.log(response);
            ElMessage({
              message: "Order Placed",
              type: "success",
            });
            // store the order Details to session storage
            sessionStorage.setItem("orderData", JSON.stringify(response.data));
            this.$router.push("/order");
          })
          .catch((error) => {
            ElMessage({
              message: "Error placing order",
              type: "error",
            });
            console.log(error);
          });
      }
    },
    fetchUserAddresses() {
      this.isFetching = true;
      this.loading = true;
      const token = localStorage.getItem("token");

      getUserAddresses(token)
        .then((response) => {
          this.addresses = response.data;
          const defaultAddress = response.data.find(
            (address) => address.isDefault
          );
          if (defaultAddress) {
            this.selectedAddress = defaultAddress;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    formatAddress(address) {
      return `${address.street}, ${address.city}, ${address.state} ${address.zip}`;
    },
    handleAddressChange() {
      console.log(this.selectedAddress);
    },
    editAddress() {
      this.dialogVisible = true;
    },
    saveAddress() {
      this.dialogVisible = false;
    },
  },
  created() {
    this.isLoggedIn = !!localStorage.getItem("token");
    if (this.isLoggedIn) {
      this.fetchUserAddresses();
    }
  },
};
</script>

<style scoped>
.customer-details {
  margin: 20px;
}
.columns {
  justify-content: center;
}
.customer-details {
  border: 2px solid #ccc;
  border-radius: 5px;
}
.form {
  margin-top: 20px;
  justify-content: center;
  display: grid;
}
</style>
